import {
  GET_DATA,
  GET_DATA_SUCCESSFUL,
  GET_DATA_FAILED,
  GET_ENTERPRISE_PROFILE,
} from "./actionTypes";

export const getProfileData = (data, history, noCache, userType) => {
  return {
    type: GET_DATA,
    payload: { data, history, noCache, userType },
  };
};
export const getDataSuccessful = (obj) => {
  return {
    type: GET_DATA_SUCCESSFUL,
    payload: obj,
  };
};
export const getEnterpriseSuccessful = (obj) => {
  return {
    type: GET_ENTERPRISE_PROFILE,
    payload: obj,
  };
};

export const getDataFailed = (error) => {
  return {
    type: GET_DATA_FAILED,
    payload: error,
  };
};
