import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
const Login = lazy(() => import("../pages/Authentication/Login"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const Register = lazy(() => import("../pages/Authentication/Register"));
const ForgetPwd = lazy(() => import("../pages/Authentication/ForgetPassword"));
const ChangePassword = lazy(() =>
  import("../pages/Authentication/ChangePassword")
);
const TermsConditions = lazy(() =>
  import("../pages/Authentication/TermsConditions")
);

// Dashboard
const AdminList = lazy(() => import("../pages/EnterPrise/AdminList"));
const AddServiceAdmin = lazy(() =>
  import("../pages/EnterPrise/AddServiceAdmin")
);
const EnterpriseSetting = lazy(() => import("../pages/EnterPrise/Setting"));
const SuperAdminSetting = lazy(() => import("../pages/SuperAdmin/Setting"));
const AddNewPreferredPartner = lazy(() =>
  import("../pages/PreferredPartner/AddNewPreferredPartner")
);
const ApprovePartner = lazy(() =>
  import("../pages/PreferredPartner/ApprovePartner")
);
const EditSetting = lazy(() => import("../pages/EnterPrise/EditSetting"));
const EditSuperAdminSettings = lazy(() =>
  import("../pages/SuperAdmin/EditSetting")
);
const PreferredPartnerList = lazy(() =>
  import("../pages/PreferredPartner/PreferredPartnerList")
);
const TradesList = lazy(() => import("../pages/Trades/TradesList"));

//service admin
const TechniciansList = lazy(() =>
  import("../pages/Technician/TechniciansList")
);
const ServiceAdminSetting = lazy(() =>
  import("../pages/ServiceAdmin/ServiceAdminSetting")
);
const EditServiceAdminSetting = lazy(() =>
  import("../pages/ServiceAdmin/EditServiceAdminSetting")
);
const AddTechnicians = lazy(() => import("../pages/Technician/AddTechnicians"));
const LiveAppointment = lazy(() =>
  import("../pages/Technician/LiveAppointment")
);
const StartCall = lazy(() => import("../pages/Technician/StartCall"));
const ShowAllImages = lazy(() => import("pages/Technician/ShowAllImages"));
const ServiceAdminDashboard = lazy(() =>
  import("../pages/ServiceAdmin/ServiceAdminDashboard")
);

//appointment
const Appointments = lazy(() => import("../pages/Appointment/Appointments"));
const AppointmentDetails = lazy(() =>
  import("../pages/Appointment/AppointmentDetails")
);
const EditAppointmentDetails = lazy(() =>
  import("../pages/Appointment/EditAppointmentDetails")
); //tech
const DeletedAppointments = lazy(() =>
  import("../pages/Appointment/DeletedAppointments")
);
const AppointmentEndScreen = lazy(() =>
  import("pages/Appointment/AppointmentEndScree")
);
const AppointmentSummary = lazy(() =>
  import("../pages/Technician/AppointmentSummary")
);
const EnterpriseDashboard = lazy(() => import("pages/EnterPrise/dashboard"));
const SuccessScreen = lazy(() => import("../pages/Authentication/404Screen"));
//email template
const EmailTemplate = lazy(() => import("pages/EnterPrise/EmailTemplate"));
const SavedTemplates = lazy(() => import("pages/EnterPrise/SavedTemplates"));
const EditTemplate = lazy(() => import("pages/EnterPrise/EditTemplate"));
// superAdmin Routes
const SuperAdminDashboard = lazy(() => import("pages/SuperAdmin/Dasboard"));
const EnterpriseList = lazy(() => import("pages/SuperAdmin/EnterpriseList"));
const AddNewEnterPrise = lazy(() =>
  import("pages/SuperAdmin/AddNewEnterprise")
);
const ViewEntpriseProfile = lazy(() =>
  import("pages/SuperAdmin/ViewEnterpriseProfile")
);
const EnterPriseLanding = lazy(() =>
  import("pages/SuperAdmin/EnterPriseLanding")
);
const InviteAdmin = lazy(() => import("pages/SuperAdmin/Invite"));
const SuperAdminLogin = lazy(() => import("pages/SuperAdmin/Login"));
const EnterpriseTheme = lazy(() => import("pages/SuperAdmin/EnterpriseTheme"));
const AcccesEnterprise = lazy(() =>
  import("pages/SuperAdmin/AccessEnterpriseAccount")
);
const AddNewSuperAdmin = lazy(() => import("pages/SuperAdmin/AddNewAdmin"));
const CreateNewPassword = lazy(() => import("pages/SuperAdmin/CreatePassword"));
//billing
const Billing = lazy(() => import("pages/SuperAdmin/Billing"));
//static pages
const StaticPage = lazy(() => import("pages/SuperAdmin/StaticPage"));
const SavedStaticPages = lazy(() =>
  import("pages/SuperAdmin/SavedStaticPages")
);
const userRoutes = [
  { path: "/admin-list", component: AdminList, name: "Service Admins" },
  {
    path: "/add-service-admin",
    component: AddServiceAdmin,
    name: "Add New Service Admin",
  },
  {
    path: "/enterprise/billing",
    component: Billing,
    name: "Billing Information",
  },

  {
    path: "/enterprise-settings",
    component: EnterpriseSetting,
    name: "Settings",
  },
  {
    path: "/edit-service-admin/:id",
    component: AddServiceAdmin,
    name: "Edit Service Admin",
  },
  {
    path: "/service-admin-setting/:id",
    component: SuperAdminSetting,
    name: "Service Admin Setting",
  },
  {
    path: "/super-admin-setting",
    component: SuperAdminSetting,
    name: "Settings",
  },
  {
    path: "/enterprise-settings/:id",
    component: SuperAdminSetting,
    name: "Settings",
  },
  {
    path: "/preferred-partner-setting/:id",
    component: SuperAdminSetting,
    name: "Preferred Partners",
  },
  {
    path: "/add-new-preferred-partner",
    component: AddNewPreferredPartner,
    name: "Add New Preferred Partner",
  },

  {
    path: "/edit-new-preferred-partner/:id",
    component: AddNewPreferredPartner,
    name: "Edit New Preferred Partner",
  },

  {
    path: "/preferred-partner-list",
    component: PreferredPartnerList,
    name: "Preferred Partners",
  },
  {
    path: "/enterprise-dashboard",
    component: EnterpriseDashboard,
  },
  {
    path: "/editEnp-setting/:id",
    component: EditSetting,
    name: "Edit Setting",
  },
  { path: "/trades-list", component: TradesList, name: "Trades" },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/preferred-partner-list" />,
  },
];

const serviceAdminRoutes = [
  {
    path: "/service-admin-dashboard",
    component: ServiceAdminDashboard,
    name: "",
  },

  {
    path: "/technicians-list",
    component: TechniciansList,
    name: "Technicians",
  },
  {
    path: "/service-admin-setting",
    component: ServiceAdminSetting,
    name: "Settings",
  },
  {
    path: "/service-admin-edit-setting/:id",
    component: EditServiceAdminSetting,
    name: "Edit Settings",
  },
  {
    path: "/service-admin-appointments",
    component: Appointments,
    name: "Appointments",
  },
  {
    path: "/service-admin-appointment-details/:id",
    component: AppointmentDetails,
    name: "Appointment With ",
  },
  {
    path: "/service-admin-edit-appointment-details/:id",
    component: EditAppointmentDetails,
    name: "Appointment With ",
  },
  {
    path: "/deleted-appointments",
    component: DeletedAppointments,
    name: "Deleted Appointments",
  },
  {
    path: "/edit-technician-service/:id",
    component: AddTechnicians,
    name: "Edit Settings",
  },
  {
    path: "/add-technician",
    component: AddTechnicians,
    name: "Add New Technician",
  },
  {
    path: "/technician-setting/:id",
    component: ServiceAdminSetting,
    name: "Technician Setting",
  },
  {
    path: "/edit-technician-settings/:id",
    component: ServiceAdminSetting,
    name: "Technician Setting",
  },
  {
    path: "/edit-technician/:id",
    component: ServiceAdminSetting,
    name: "Edit Setting",
  },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/technicians-list" />,
  },
];

const technicianRoutes = [
  {
    path: "/technician-appointments",
    component: Appointments,
    name: "Appointments",
  },
  {
    path: "/technician-appointment-details/:id",
    component: AppointmentDetails,
    name: "Appointment With ",
  },
  {
    path: "/technician-edit-appointment-details/:id",
    component: EditAppointmentDetails,
    name: "Appointment With ",
  },
  {
    path: "/appointment-end-screen/:id",
    component: AppointmentEndScreen,
    name: "Summary of Appointment with ",
  },
  {
    path: "/technician-setting",
    component: ServiceAdminSetting,
    name: "Setting",
  },
  {
    path: "/edit-technician-setting/:id",
    component: AddTechnicians,
    name: "Edit Settings",
  },
  {
    path: "/live-appointment/:id",
    component: LiveAppointment,
    name: "Appointment With ",
  },
  {
    path: "/all-images/:id",
    component: ShowAllImages,
    name: "All Images",
  },
  {
    path: "/start-call/:id",
    component: StartCall,
    name: "Appointment With ",
  },
  {
    path: "/technician-deleted-appointments",
    component: DeletedAppointments,
    name: "Deleted Appointments",
  },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/technicians-list" />,
  },
];
const superAdminRoutes = [
  {
    path: "/super/dashboard",
    component: SuperAdminDashboard,
    name: "Dashboard",
  },

  {
    path: "/email-template",
    component: EmailTemplate,
    name: "Email Editor",
  },
  {
    path: "/edit-template/:id",
    component: EditTemplate,
    name: "Edit Template",
  },
  {
    path: "/saved-templates",
    component: SavedTemplates,
    name: "Saved Templates",
  },
  {
    path: "/edit-setting/:id",
    component: EditSuperAdminSettings,
    name: "Edit Setting",
  },

  {
    path: "/super/viewEnterpriseProfile/:id",
    component: ViewEntpriseProfile,
    name: "",
  },
  {
    path: "/super/enterprise-list",
    component: EnterpriseList,
    name: "Enterprise List",
  },
  {
    path: "/super/addNewEnterprise",
    component: AddNewEnterPrise,
    name: "Add New Enterprise",
  },
  {
    path: "/super/edit-enterprise/:id",
    component: AddNewEnterPrise,
    name: "Edit Entrprise",
  },
  {
    path: "/super/static-pages",
    component: StaticPage,
    name: "Static Pages",
  },
  {
    path: "/super/save-static-pages",
    component: SavedStaticPages,
    name: "Edit Static Page",
  },

  {
    path: "/super/super-admin-setting",
    component: SuperAdminSetting,
    name: "Settings",
  },
  {
    path: "/super/enterprise-setttings/:id",
    component: ServiceAdminSetting,
    name: "Enterprise Setting",
  },

  {
    path: "/super",
    exact: true,
    component: () => <Redirect to="/super/login" />,
  },
];

const nonAuthSuper = [
  { path: "/super/login", component: SuperAdminLogin },
  {
    path: "/super/enterprise-access-account",
    component: AcccesEnterprise,
  },
  { path: "/super/change-password/:id", component: ChangePassword },
  { path: "/super/create-fresh-password", component: CreateNewPassword },
  {
    path: "/super/enterprise-landing",
    component: EnterPriseLanding,
    name: "Enterprise Admin Profile",
  },
  {
    path: "/super/invite",
    component: InviteAdmin,
    name: "Invite",
  },
  {
    path: "/super/signup/:invitecode",
    component: AddNewSuperAdmin,
    name: "Add New Admin",
  },
  {
    path: "/super/enterprise-theme",
    component: EnterpriseTheme,
    name: "EnterpriseTheme",
  },
];
const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/change-password/:id", component: ChangePassword },
  { path: "/terms-conditions", component: TermsConditions },
  { path: "/approve-preferred-partner/:id", component: ApprovePartner },
  { path: "/appointment-summary/:id", component: AppointmentSummary },
  { path: "/error", component: SuccessScreen },
  { path: "/super/forgot-password", component: ForgetPwd },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  // { path:"*", exact: true, component: () => <Redirect to="/error" />},
  // { path:"", exact: true, component: () => <Redirect to="/error" />},
];

export {
  userRoutes,
  authRoutes,
  serviceAdminRoutes,
  technicianRoutes,
  superAdminRoutes,
  nonAuthSuper,
};
