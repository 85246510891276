import {
  ADD_PARTNER,
  ADD_PARTNER_SUCCESSFUL,
  ADD_PARTNER_FAILED,
  API_ERROR,
} from "./actionTypes"

export const addPartner = (user, file, profilePic, trades, history) => {
  return {
    type: ADD_PARTNER,
    payload: { user, file, profilePic, trades, history },
  }
}

export const addPartnerSuccessful = user => {
  return {
    type: ADD_PARTNER_SUCCESSFUL,
    payload: user,
  }
}

export const addPartnerFailed = error => {
  return {
    type: ADD_PARTNER_FAILED,
    payload: error,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
