import {
  TECHNIAN_DASHBOARD_COUNT_FAILURE,
  TECHNIAN_DASHBOARD_COUNT_SUCCESS,
  TECHNIAN_DASHBORD_COUNT,
} from "./actionTypes"
export const techDashboardCount = () => {
  return {
    type: TECHNIAN_DASHBORD_COUNT,
  }
}

export const techDashCountSuccess = data => {
  return {
    type: TECHNIAN_DASHBOARD_COUNT_SUCCESS,
    payload: data,
  }
}

export const techDashCountFailure = error => {
  return {
    type: TECHNIAN_DASHBOARD_COUNT_FAILURE,
    payload: error,
  }
}
