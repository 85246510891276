import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

//Account Redux states
import { TECHNIAN_DASHBORD_COUNT } from "./actionTypes"
import { techDashCountSuccess, techDashCountFailure } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { getDashboardCount } from "../../../helpers/fakebackend_helper"

function* getTechCount() {
  try {
    const response = yield call(getDashboardCount)
    yield put(techDashCountSuccess(response))
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
    }
    yield put(techDashCountFailure(error.response))
  }
}

function* TechAdminSaga() {
  yield takeLatest(TECHNIAN_DASHBORD_COUNT, getTechCount)
}

export default TechAdminSaga
