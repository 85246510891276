import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import Logo from "assets/images/Service-call-logo-light.png"
//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div onClick={() => history.push("/super/dashboard")} className="navbar-brand-box  py-3">
          <span><img src={Logo} /></span>
          {/* <img src={props.logoSrc} onClick={() => history.push("/super/dashboard")} alt="Logo" /> */}
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
