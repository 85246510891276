import {
  CREATE_USER,
  CREATE_USER_SUCCESSFUL,
  CREATE_USER_FAILED,
  EDIT_USER,
  EDIT_USER_SUCCESSFUL,
  EDIT_USER_FAILED,
  API_ERROR,
} from "./actionTypes"

export const createUser = (user, file, history) => {
  return {
    type: CREATE_USER,
    payload: { user, file, history },
  }
}

export const createUserSuccessful = user => {
  return {
    type: CREATE_USER_SUCCESSFUL,
    payload: user,
  }
}

export const createUserFailed = error => {
  return {
    type: CREATE_USER_FAILED,
    payload: error,
  }
}

export const editUser = user => {
  return {
    type: EDIT_USER,
    payload: { user },
  }
}

export const editUserSuccessful = user => {
  return {
    type: EDIT_USER_SUCCESSFUL,
    payload: user,
  }
}

export const editUserFailed = error => {
  return {
    type: EDIT_USER_FAILED,
    payload: error,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
