import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
// import PartnerLogin from "./auth/partnerLogin/reducer"
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ChangePassword from "./auth/changepwd/reducer";
import Profile from "./auth/profile/reducer";

import admin from "./serviceadmin/addserviceadmin/reducer";
import Admins from "./serviceadmin/serviceadminlist/reducer";
import PreferredParttners from "./preferredpartner/list/reducer";
import PreferredPartner from "./preferredpartner/add/reducer";
import Trades from "./trades/add/reducer";
import TradesList from "./trades/list/reducer";
import TradesDelete from "./trades/delete/reducer";
import ProfileData from "./getProfile/reducer";
import Toggle from "./toggleStatus/reducer";
import Technician from "./technician/addtechnician/reducer";
import TechnicianList from "./technician/technicianlist/reducer";
import Appointment from "./makeappointment/reducer";
import TechnianDashboard from "./technician/dashboard/reducer";
import EmailTemplateReducer from "./emailTemplate/reducer";
import EnterPrise from "./enterprise/reducer";
// //Calendar
// import calendar from "./calendar/reducer"

// //chat
// import chat from "./chat/reducer"

// //crypto
// import crypto from "./crypto/reducer"

// //invoices
// import invoices from "./invoices/reducer"

// //projects
// import projects from "./projects/reducer"

// //mails
// import mails from "./mails/reducer"

// //tasks
// import tasks from "./tasks/reducer"

// //contacts
// import contacts from "./contacts/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ChangePassword,
  Profile,
  admin,
  Admins,
  PreferredParttners,
  PreferredPartner,
  Trades,
  TradesList,
  ProfileData,
  TechnianDashboard,
  Toggle,
  TradesDelete,
  EmailTemplateReducer,
  Technician,
  TechnicianList,
  Appointment,
  EnterPrise,
});

export default rootReducer;
