import React from "react"
import logo from "../src/assets/images/no-internet.png"
import { Link, useHistory } from "react-router-dom"
function NotFound() {
  const history = useHistory()
  return (
    <div class="error-card">
      <div class="w-100">
        <div>
          <img src={logo} />
        </div>
        <h2 style={{ lineHeight: "normal" }} className="no_internet_h2">
          Oops! Error 404
        </h2>
        <p style={{ fontSize: "14px" }} className="no_internet_p">
          Looks like we’re broken ...Go back to the homepage and try again.
        </p>
        <div className="no_internet_text-center " style={{ marginTop: "50px" }}>
          <Link onClick={history.goBack} className="no_internet_back-btn">
            Go Back
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
