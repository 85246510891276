import {
  MAKE_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_INACTIVE_APPOINTMENTS,
  GET_APPOINTMENTS_DETAILS,
  REJECT_APPOINTMENT,
  ACCEPT_APPOINTMENT,
  GET_APPOINTMENTS_DATA_SUCCESSFUL,
  GET_APPOINTMENTS_SUCCESSFUL,
  GET_APPOINTMENTS_FAILED,
  SEND_APPOINTMENT_DETAIL,
  SEND_APPOINTMENT,
  POST_APPOINTMENT_IMAGES,
  POST_APPOINTMENT_PDF,
  REFER_APPOINTMENT,
  GET_DELETE_APPOINTMENTS_DATA_SUCCESSFUL,
} from "./actionTypes"

export const getappointment = (data, mediaFiles, files, history) => {
  return {
    type: MAKE_APPOINTMENT,
    payload: { data, mediaFiles, files, history },
  }
}

export const getappointments = search => {
  return {
    type: GET_APPOINTMENTS,
    payload: { search },
  }
}

export const getInactiveAppointments = (pageNo, pageSize, search) => {
  return {
    type: GET_INACTIVE_APPOINTMENTS,
    payload: { pageNo, pageSize, search },
  }
}

export const getappointmentsdetails = obj => {
  return {
    type: GET_APPOINTMENTS_DETAILS,
    payload: { obj },
  }
}

export const rejectAppointment = (obj, history) => {
  return {
    type: REJECT_APPOINTMENT,
    payload: { obj, history },
  }
}

export const acceptAppointment = (obj, history) => {
  return {
    type: ACCEPT_APPOINTMENT,
    payload: { obj, history },
  }
}

export const sendappointment = data => {
  return {
    type: SEND_APPOINTMENT_DETAIL,
    payload: { data },
  }
}

export const postAppointmentsImages = obj => {
  return {
    type: POST_APPOINTMENT_IMAGES,
    payload: { obj },
  }
}

export const postAppointmentsPdf = obj => {
  return {
    type: POST_APPOINTMENT_PDF,
    payload: { obj },
  }
}

export const referAppointments = obj => {
  return {
    type: REFER_APPOINTMENT,
    payload: { obj },
  }
}

export const sendappointmentCustomer = obj => {
  return {
    type: SEND_APPOINTMENT,
    payload: { obj },
  }
}

export const appointmentdataSuccessful = data => {
  return {
    type: GET_APPOINTMENTS_DATA_SUCCESSFUL,
    payload: data,
  }
}

export const appointmentdeletedataSuccessful = data => {
  return {
    type: GET_DELETE_APPOINTMENTS_DATA_SUCCESSFUL,
    payload: data,
  }
}

export const appointmentSuccessful = success => {
  return {
    type: GET_APPOINTMENTS_SUCCESSFUL,
    payload: success,
  }
}

export const appointmentFailed = error => {
  return {
    type: GET_APPOINTMENTS_FAILED,
    payload: error,
  }
}
