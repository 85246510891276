export const MAKE_APPOINTMENT= "make_appintment"
export const MAKE_APPOINTMENT_SUCCESSFUL = "make_appintment_success"
export const MAKE_APPOINTMENT_FAILED = "make_appintment_fail"

export const REJECT_APPOINTMENT = "REJECT_APPOINTMENT"
export const ACCEPT_APPOINTMENT = "ACCEPT_APPOINTMENT"
export const REJECT_APPOINTMENT_SUCCESSFUL = "REJECT_APPOINTMENT_SUCCESSFUL"
export const REJECT_APPOINTMENT_FAILED = "REJECT_APPOINTMENT_FAILED"

export const GET_APPOINTMENTS= "make_appintments"
export const GET_INACTIVE_APPOINTMENTS= "GET_INACTIVE_APPOINTMENTS"
export const GET_APPOINTMENTS_SUCCESSFUL = "make_appintments_success"
export const GET_APPOINTMENTS_FAILED = "make_appintments_fail"
export const GET_APPOINTMENTS_DATA_SUCCESSFUL = "GET_APPOINTMENTS_DATA_SUCCESSFUL"
export const GET_DELETE_APPOINTMENTS_DATA_SUCCESSFUL = "GET_DELETE_APPOINTMENTS_DATA_SUCCESSFUL"
export const POST_APPOINTMENT_IMAGES= "POST_APPOINTMENT_IMAGES"
export const POST_APPOINTMENT_PDF = "POST_APPOINTMENT_PDF"
export const GET_APPOINTMENTS_DETAILS = "GET_APPOINTMENTS_DETAILS"

export const SEND_APPOINTMENT = "SEND_APPOINTMENT"
export const SEND_APPOINTMENT_DETAIL = "SEND_APPOINTMENT_DETAIL"
export const REFER_APPOINTMENT = "REFER_APPOINTMENT"