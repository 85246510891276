import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { CREATE_TECHNICIAN } from "./actionTypes"
import { createTechnicianSuccessful, createTechnicianFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { createTechnicianData } from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* createTechnician({ payload: { user, file, trades, history } }) {
  const formData = new FormData()
  Object.keys(user).forEach(key => {
    formData.append(key, user[key])
  })
  formData.append("profilePic", file)
  formData.append("trades", JSON.stringify(trades))
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(createTechnicianData, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      if (response) {
        yield put(createTechnicianSuccessful(response.message))
        if (window.location.pathname.includes("edit-technician-setting")) {
          setTimeout(() => {
            history.push("/technician-setting")
          }, 2000)
        } else {
          setTimeout(() => {
            history.push("/technicians-list")
          }, 2000)
        }
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(createTechnicianFailed(error.response.data.message))
  }
}

export function* watchTechnicianRegister() {
  yield takeEvery(CREATE_TECHNICIAN, createTechnician)
}

function* TechnicianSaga() {
  yield all([fork(watchTechnicianRegister)])
}

export default TechnicianSaga
