import { takeEvery, put, call, delay } from "redux-saga/effects";

//Account Redux states
import {
  ACCESS_ACOUNT_BY_EMAIL,
  CREATE_NEW_ENTERPRISE,
  ENTERPRISE_SIGNUP,
  GET_ENTERPRISES,
  UPLOAD_LOGO,
  SET_THEME_COLOR,
  TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS,
  TOGGLE_ENTERPRISE_STATUS,
  EDIT_ENPP_PROFILE,
  NEW_SUPER_ADMIN_SIGNUP,
  SET_ADMIN_PASSWORD,
  EDIT_BILLING_INFO,
} from "./actionTypes";
import {
  getEnterprisesSucess,
  getEnterprisesFailed,
  enpProfileSuccess,
  enpProfileError,
  signupNewSuperAdminSuccess,
  signupNewSuperAdminFailed,
  editBillingInfoSuccessful,
  editBillingInfoFailed,
  setPasswordSuccess,
} from "./actions";

import {
  enterpriseSignupApi,
  getEnterprises,
  createEnterprise,
  getEnterPriseAccountByMail,
  uploadEnterpriseLogo,
  setThemeColor,
  toggleEnterprisePartnerStatus,
  toggleEnterPriseStatus,
  newSuperSignup,
  addEditBillingInfo,
  editProfileApi,
  setPassword,
} from "../../helpers/fakebackend_helper";
import {
  accessAcountByEmailFailed,
  accessAcountByEmailSuccess,
  createNewEnterpriseFailed,
  createNewEnterpriseSuccess,
  enterpriseSignupFailed,
  enterpriseSignupSuccess,
  setThemeColorFailed,
  setThemeColorSuccess,
  toggleEnterprisePreferredPartnerStatusSuccess,
  toggleEnterPriseStatusFailed,
  toggleEnterPriseStatusSuccess,
  uploadLogoFailed,
  uploadLogoSuccess,
  toggleEnterprisePreferredPartnerStatusFailed,
} from "store/actions";

function* editEProfile({ payload: { user, file, history } }) {
  const formData = new FormData();
  Object.keys(user).forEach((key) => {
    formData.append(key, user[key]);
  });
  formData.append("profilePic", file);
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(editProfileApi, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      yield put(enpProfileSuccess(response.message));
      history.push("/enterprise-settings");
    }
  } catch (error) {
    yield put(enpProfileError(error));
  }
}

function* fetchAllEnterprises({ payload: { pageNo, pageSize, search } }) {
  try {
    const response = yield call(getEnterprises, pageNo, pageSize, search);
    yield put(getEnterprisesSucess(response));
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
    }
    yield put(getEnterprisesFailed(error.response));
  }
}
function* createEnterPrise({ payload: { data, history } }) {
  try {
    const response = yield call(createEnterprise, data);

    yield put(createNewEnterpriseSuccess(response.message));
    setTimeout(() => {
      history.push("/super/enterprise-list");
    }, 2000);
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
    }
    yield put(createNewEnterpriseFailed(error.response.data.message));
  }
}

function* accesAccountByEmail({ payload: { email, history } }) {
  try {
    const response = yield call(getEnterPriseAccountByMail, email);
    if (response.enterprise === null) {
      yield put(accessAcountByEmailFailed("Invalid does not exist"));
    } else {
      yield put(accessAcountByEmailSuccess(response.enterprise));
      localStorage.setItem("enterprise", JSON.stringify({ ...response.enterprise, password: "" }));
      setTimeout(() => {
        history.push("/super/enterprise-landing");
      }, 2000);
    }
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
    }
    yield put(accessAcountByEmailFailed(error.response.data.message));
  }
}

function* enterpriseSignup({ payload: { data, history } }) {
  try {
    const response = yield call(enterpriseSignupApi, data);

    yield put(enterpriseSignupSuccess(response.message));
    setTimeout(() => {
      history.push("/super/enterprise-theme");
    }, 2000);
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
    }
    yield put(enterpriseSignupFailed(error.response.data.message));
  }
}

function* uploadLogo({ payload: { data, file, history } }) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("profilePic", file);
  try {
    const response = yield call(uploadEnterpriseLogo, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response) {
      yield put(uploadLogoSuccess(response.message));
      setTimeout(() => {}, 10000);
    }
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
      // window.location = "/super/login"
    }
    yield put(uploadLogoFailed(error.response.data.message));
  }
}
function* themeColor({ payload: { data, history } }) {
  try {
    const response = yield call(setThemeColor, data);

    yield put(setThemeColorSuccess(response.message));
    setTimeout(() => {
      history.push("/login");
    }, 3000);
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
    }
    yield put(setThemeColorFailed(error.response.data.message));
  }
}
function* toggleEnterpriseActiveStatus({ payload: { data, history, cb } }) {
  try {
    const response = yield call(toggleEnterPriseStatus, data);
    if (response) {
      yield put(toggleEnterPriseStatusSuccess(response.message));

      return cb(response);
    }
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
    }
    yield put(toggleEnterPriseStatusFailed(error.response.data.message));
  }
}
function* toggleEnterprisePartner({ payload: { data, history, cb } }) {
  try {
    const response = yield call(toggleEnterprisePartnerStatus, data);
    if (response) {
      yield put(toggleEnterprisePreferredPartnerStatusSuccess(response.message));

      return cb(response);
    }
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
      history.push("/super/enterprise-list");
    }
    yield put(toggleEnterprisePreferredPartnerStatusFailed(error.response.data.message));
  }
}

function* setAdminPassword({ payload: { data } }) {
  const response = yield call(setPassword, data);
  if (response) {
    yield put(setPasswordSuccess(true));
  } else {
    yield put(setPasswordSuccess(false));
  }
}

function* signupNewAdmin({ payload: { data, file, history } }) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("profilePic", file);
  try {
    const response = yield call(newSuperSignup, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response) {
      yield put(signupNewSuperAdminSuccess(response.message));
    }
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
    }
    yield put(signupNewSuperAdminFailed(error.response.data.message));
  }
}

function* editBillingDetails({ payload: { data, history } }) {
  try {
    const response = yield call(addEditBillingInfo, data);
    if (response) {
      yield put(editBillingInfoSuccessful(response.message));
      setTimeout(() => {
        history.push("/super/enterprise-list");
      }, 2000);
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
    }
    yield put(editBillingInfoFailed(error.response.data.message));
  }
}

function* EnterprisesListSaga() {
  yield takeEvery(GET_ENTERPRISES, fetchAllEnterprises);
  yield takeEvery(CREATE_NEW_ENTERPRISE, createEnterPrise);
  yield takeEvery(ACCESS_ACOUNT_BY_EMAIL, accesAccountByEmail);
  yield takeEvery(ENTERPRISE_SIGNUP, enterpriseSignup);
  yield takeEvery(UPLOAD_LOGO, uploadLogo);
  yield takeEvery(SET_THEME_COLOR, themeColor);
  yield takeEvery(TOGGLE_ENTERPRISE_STATUS, toggleEnterpriseActiveStatus);
  yield takeEvery(TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS, toggleEnterprisePartner);
  yield takeEvery(EDIT_ENPP_PROFILE, editEProfile);
  yield takeEvery(SET_ADMIN_PASSWORD, setAdminPassword);
  yield takeEvery(NEW_SUPER_ADMIN_SIGNUP, signupNewAdmin);
  yield takeEvery(EDIT_BILLING_INFO, editBillingDetails);
}

export default EnterprisesListSaga;
