/**
 * @Author: ZEESHAN AHMAD
 * @Date:   2021-11-01 01:11:41
 * @Last Modified by:   ZEESHAN AHMAD
 * @Last Modified time: 2021-11-03 04:30:58
 */
import { takeEvery, put, call } from "redux-saga/effects"

//Account Redux states
import {
  ADD_NEW_TEMPLATE,
  VIEW_SAVED_TEMPLATES,
  LOAD_TEMPLATE,
} from "./actionTypes"
import { addNewTemplateSuccess, addNewTemplateFailed } from "./actions"

// Api's
import {
  saveNewTemplate,
  getAdminSavedTemplates,
  getTemplate,
} from "../../helpers/fakebackend_helper"
import {
  loadTemplateFailed,
  loadTemplateSuccess,
  viewSavedTemplatesFailed,
  viewSavedTemplatesSuccess,
} from "store/actions"

function* createNewTemplate({ payload: { obj, history } }) {
  try {
    const response = yield call(saveNewTemplate, obj)
    if (response) {
      yield put(addNewTemplateSuccess(response.message))
      setTimeout(() => {
        history.push("/saved-templates")
      }, 4000)
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
    }
    yield put(addNewTemplateFailed(error))
  }
}

function* getAllSavedTemplates() {
  try {
    const response = yield call(getAdminSavedTemplates)
    if (response) {
      yield put(viewSavedTemplatesSuccess(response.data))
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
    }
    yield put(viewSavedTemplatesFailed(error))
  }
}

function* loadTemplate({ payload: { id } }) {
  try {
    const response = yield call(getTemplate, id)
    if (response) {
      yield put(loadTemplateSuccess(response.data))
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      // window.location = "/login"
    }
    yield put(loadTemplateFailed(error))
  }
}

function* TemplateSaga() {
  yield takeEvery(ADD_NEW_TEMPLATE, createNewTemplate)
  yield takeEvery(VIEW_SAVED_TEMPLATES, getAllSavedTemplates)
  yield takeEvery(LOAD_TEMPLATE, loadTemplate)
}

export default TemplateSaga
