export const TOGGLE_STATUS = "toggle_status"
export const TECH_TOGGLE_STATUS = "TECH_TOGGLE_STATUS"
export const TOGGLE_SUCCESSFUL = "toggle_success"
export const TOGGLE_FAILED = "toggle_fail"

export const ARCHIVED_STATUS = "ARCHIVED_STATUS"
export const ARCHIVED_SUCCESSFUL = "ARCHIVED_SUCCESSFUL"
export const ARCHIVED_FAILED = "ARCHIVED_FAILED"
export const DELETE_PARTNER = "DELETE_PARTNER"

