import {
  GET_DATA,
  GET_DATA_SUCCESSFUL,
  GET_DATA_FAILED,
  GET_ENTERPRISE_PROFILE,
} from "./actionTypes";

const initialState = {
  listError: null,
  data: [],
  enterpriseProfile: [],
  loading: false,
};

const ProfileData = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_DATA_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        data: action.payload,
      };
      break;
    case GET_ENTERPRISE_PROFILE:
      state = {
        ...state,
        loading: false,
        enterpriseProfile: action.payload,
      };
      break;
    case GET_DATA_FAILED:
      state = {
        ...state,
        loading: false,
        listError: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ProfileData;
