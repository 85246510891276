import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  INVITE_NEW_SUPER_ADMIN,
  INVITE_NEW_SUPER_ADMIN_SUCCESS,
  INVITE_NEW_SUPER_ADMIN_FAILED,
  GET_NEW_SUPER_LINK,
  GET_NEW_SUPER_LINK_SUCCESS,
  GET_NEW_SUPER_LINK_FAILED,
  RESET_MESSAGE,
} from "./actionTypes";

export const registerUser = (user) => {
  return {
    type: REGISTER_USER,
    payload: { user },
  };
};

export const resetMessage = () => {
  return { type: RESET_MESSAGE };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (user) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  };
};
export const inviteNewSuperAdmin = (email, history) => {
  return {
    type: INVITE_NEW_SUPER_ADMIN,
    payload: { email, history },
  };
};
export const inviteNewSuperAdminSuccess = (data) => {
  return {
    type: INVITE_NEW_SUPER_ADMIN_SUCCESS,
    payload: data,
  };
};
export const inviteNewSuperAdminFailed = (error) => {
  return {
    type: INVITE_NEW_SUPER_ADMIN_FAILED,
    payload: error,
  };
};

export const getSuperLinkInfo = (inviteUrl) => {
  return {
    type: GET_NEW_SUPER_LINK,
    payload: { inviteUrl },
  };
};
export const getSuperLinkInfoSuccess = (data) => {
  return {
    type: GET_NEW_SUPER_LINK_SUCCESS,
    payload: data,
  };
};
export const getSuperLinkInfoFailed = (error) => {
  return {
    type: GET_NEW_SUPER_LINK_FAILED,
    payload: error,
  };
};
