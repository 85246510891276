import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { GET_LIST, GET_ARCHIVED_LIST } from "./actionTypes"
import {
  getListSuccessful,
  getListFailed,
  getArchiveListSuccessful,
  getArchiveListFailed,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  getData,
  getArchiveListData,
} from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* fetchProducts({ payload: { pageNo, pageSize, search, data } }) {
  let obj = {"perPage": pageSize, "page": pageNo, "search":search,  "status": data}
  try {
    const response = yield call(getData, obj)

    yield put(getListSuccessful(response))
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(getListFailed(error.response))
  }
}

function* fetchList({ payload: { pageNo, pageSize, search } }) {
  try {
    const response = yield call(getArchiveListData, pageNo, pageSize, search)

    yield put(getArchiveListSuccessful(response))
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(getArchiveListFailed(error.response))
  }
}

function* PreferredParttnersListSaga() {
  yield takeEvery(GET_LIST, fetchProducts)
  yield takeEvery(GET_ARCHIVED_LIST, fetchList)
}

export default PreferredParttnersListSaga
