export const ADD_NEW_TEMPLATE = "ADD_NEW_TEMPLATE"
export const ADD_NEW_TEMPLATE_SUCCESSFUL = "ADD_NEW_TEMPLATE_SUCCESSFUL"
export const ADD_NEW_TEMPLATE_FAILED = "ADD_NEW_TEMPLATE_FAILED"
export const VIEW_SAVED_TEMPLATES = "VIEW_SAVED_TEMPLATES"
export const VIEW_SAVED_TEMPLATES_SUCCESS = "VIEW_SAVED_TEMPLATES_SUCCESS"
export const VIEW_SAVED_TEMPLATES_FAILED = "VIEW_SAVED_TEMPLATES_FAILED"
export const LOAD_TEMPLATE = "LOAD_TEMPLATE"
export const LOAD_TEMPLATE_SUCCESS = "LOAD_TEMPLATE_SUCCESS"
export const LOAD_TEMPLATE_FAILURE = "LOAD_TEMPLATE_FAILURE"
export const EDIT_TEMPLATE = "EDIT_TEMPLATE"
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS"
export const EDIT_TEMPLATE_FAILURE = "EDIT_TEMPLATE_FAILURE"
