import {
  ADD_TRADE,
  ADD_TRADE_SUCCESSFUL,
  ADD_TRADE_FAILED,
} from "./actionTypes"

const initialState = {
  addError:null,
  addData:null,
  loading: false,
}

const Trades = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TRADE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_TRADE_SUCCESSFUL:
      state = {
        ...state,
        addData: action.payload,
        loading: false,
        addError: null,
      }
      break
    case ADD_TRADE_FAILED:
      state = {
        ...state,
        loading: false,
        addError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Trades
