import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { DELETE_TRADE } from "./actionTypes"
import { deleteTradeSuccessful, deleteTradeFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { tradedelete } from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

function* deleteTrade({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(tradedelete, user)
      if (response) {
        yield put(deleteTradeSuccessful(response.message))
        location.reload()
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(deleteTradeFailed(error.response.data.message.message))
  }
}

export function* watchUserRegister() {
  yield takeEvery(DELETE_TRADE, deleteTrade)
}

function* TradesDeleteSaga() {
  yield all([fork(watchUserRegister)])
}

export default TradesDeleteSaga
