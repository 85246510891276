import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CHANGE_PASSWORD } from "./actionTypes";
import { userChangePasswordSuccess, userChangePasswordError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  changepwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* changepwdUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(changepwd, user);
      if (response) {
        localStorage.setItem("token", response.token);
        yield put(userChangePasswordSuccess(response.message));
        // setTimeout(() => {
        //   window.location = "/login"
        // }, 2000)
      }
    }
  } catch (error) {
    if (error.response && error.response.data.message) {
      yield put(userChangePasswordError(error.response.data.message));
    } else {
      yield put(userChangePasswordError("Something went wrong"));
    }
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(CHANGE_PASSWORD, changepwdUser);
}

function* changePasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default changePasswordSaga;
