//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/login";
export const SET_PASSWORD = "auth/setPassword";
export const POST_USER = "/admin/upsertServiceAdmin";
export const GET_ADMIN_LIST = "/admin/serviceAdminList";
export const GET_LIST = "/admin/preferredPartnerList";
export const GET_ARCHIVE_LIST = "/admin/getArchiveList";
export const ADD_PARTNER = "admin/upsertPartner";
export const GET_Trade_LIST = "/admin/getTrades";
export const GET_ENTERPRISE_TRADE_LIST = "/admin/getEnterpriseTrades";
export const ADD_TRADE = "/admin/upsertTrade";
export const GET_PROFILE_LIST = "/admin/getProfile";
export const GET_TOGGLE = "/admin/togglePartnerStatus";
export const GET_TECH_TOGGLE = "/admin/toggleTechnicianStatus";
export const GET_DELETE = "/admin/deletePartnerRequest";
export const GET_ARCHIVED = "/admin/submitPartnerApproval";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/auth/forgotPassword";
export const PASSWORD_FORGET = "/auth/resetPassword";
export const DEL_TRADE = "/admin/deleteTrade";
export const EDIT_PROFILE = "/admin/editProfile";
export const GET_APPOINTMENT = "/admin/upsertAppointment";
export const GET_APPOINTMENTS = "/admin/getAllAppointments";
export const GET_APPOINTMENT_DATA = "/admin/getAppointmentDetail";
export const EDIT_CLIENT = "/user/editProfile";
export const SAVE_PAYMENT = "/user/updatePaymentDetails";
export const SAVE_CLIENT_DETAILS = "/auth/signup";
export const REGISTER_PARTNER = "/user/partnerSignup";
export const VARIFY_PARTNER = "/user/sendEmailotp";
export const VARIFY_OTP = "/user/verifyEmail";
export const GET_PARTNER_DATA = "/auth/getProfile";
export const POST_TECHNICIAN = "/admin/upsertTechnician";
export const GET_TECHNICIAN_LIST = "/admin/technicianListByStatus";
export const REJECT_APPOINTMENT = "/admin/deleteAppointment";
export const ACCEPT_APPOINTMENT = "/admin/activateAppointments";
export const GET_INACTIVE_APPOINTMENTS = "/admin/getDeletedAppointments";
export const GET_CLIENT_DATA = "/auth/getProfile";
export const SEND_APPOINTMENT = "/auth/getProfile";
export const SEND_APPOINTMENT_CUSTOMER = "/appointment/sendAppointmentMail";
export const DOWNLOAD_APPOINTMENT_PDF = "/appointment/downloadAppointmentPdf";
export const DOWNLOAD_APPOINTMENT_IMAGES = "/appointment/downloadAppointmentImages";
export const REFER_APPOINTMENT_TO_PARTNER = "/user/getNearByUsers";

export const POST_FAKE_JWT_LOGIN = "/auth/login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//DASHBOARD DATA
export const GET_DASHBOARD_COUNT = "/admin/getDashboardData";

// Email template
export const UPSERT_EMAIL_TEMPLATE = "/admin/upsertEmailTemplate";
export const GET_ALL_SAVED_TEMPLATES = "/admin/templateList";
export const LOAD_TEMPLATE = "/admin/templateDetail";

// Enterprise
export const GET_ENTERPRISE_LIST = "/admin/enterpriseList";
export const CREATE_ENTERPRISE = "/admin/upsertEnterPrise";
export const ACCESS_ENTERPRISE_ACCOUNT = "/enterprise/getEnterpriseByMail";
export const ENTERPRISE_SIGNUP = "/enterprise/signupEnterprise";
export const UPLOAD_LOGO = "enterprise/uploadLogo";
export const ADD_THEME_COLOR = "/enterprise/addThemeColor";
export const TOOGLE_ENTERPRISE_STATUS = "/admin/toggleEnterpriseStatus";
export const TOGGLE_ENTERPRISE_PARTNER_STATUS = "/admin/toggleEnterprisePartnerStatus";
export const DELETE_ENTERPRISE = "/enterprise";
export const ENTERPRISE_DASHBOARD = "/appointment/getDashboardData";

//Adding new super admin
export const INVITE_NEW_SUPER_ADMIN = "/admin/sendSuperAdminInvitation";
export const GET_NEW_SUPER_INVITE_LINK_INFO = "/admin/getLinkInfo";
export const NEW_SUPER_ADMIN_SIGNUP = "/admin/upsertSuperAdmin";
export const EDIT_BILLING_INFO = "/admin/addPaymentDetails";
export const DELETE_SUPERADMIN = "/admin/delete-superadmin";

//payment
export const ADD_CARD = "/user/addCard";
export const GET_ALL_CARDS = "/admin/getCards";
export const UPDATE_DEFAULT_CARD = "/admin/setDefault";
export const DELETE_CARD = "/admin/deleteCard";