import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, LOGIN_SUPER_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { getEnterpriseSuccessful } from "../../getProfile/actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import { initToken } from "../../../helpers/api_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const obj = {
        email: user.email,
        password: user.password,
      };
      const response = yield call(postJwtLogin, obj);
      if (response.data && response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("authUser", response.data.user.firstName);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        yield put(loginSuccess(response.data.token));
        yield call(initToken);
        if (localStorage.getItem("token")) {
          yield delay(200);
          if (response.data.user.userType == "Enterprise") {
            yield put(getEnterpriseSuccessful(response.data.user));
            history.push("/enterprise-dashboard");
          } else {
            yield put(apiError("Invalid Email or Password"));
          }
        } else {
          console.log("no token available");
        }
      } else {
        yield put(apiError(response.message));
      }
    }
  } catch (error) {
    yield put(apiError(error.response.data.message));
  }
}

// login or enterprise and super admin
function* superUserLogin({ payload: { user, history } }) {
  console.log("super user login saga");
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const obj = {
        email: user.email,
        password: user.password,
      };
      const response = yield call(postJwtLogin, obj);
      if (response.data && response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("authUser", response.data.user.firstName);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        yield put(loginSuccess(response.data.token));
        yield call(initToken);
        if (localStorage.getItem("token")) {
          delay(200);

          if (response.data.user.userType == "Super Admin") {
            history.push("/super/dashboard");
          } else {
            yield put(apiError("Invalid Email or Password"));
          }
        } else {
          yield put(apiError("Wrong Email or Password"));
        }
      } else {
        yield put(apiError(response.message));
      }
    }
  } catch (error) {
    yield put(apiError(error.response.data.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(LOGIN_SUPER_USER, superUserLogin);
}

export default authSaga;
