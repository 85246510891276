import {
  GET_TRADE_LIST,
  GET_TRADE_LIST_SUCCESSFUL,
  GET_TRADE_LIST_FAILED,
  GET_ENTERPRISE_TRADE_LIST,
  GET_ENTERPRISE_TRADE_LIST_SUCCESSFUL,
  GET_ENTERPRISE_TRADE_LIST_FAILED,
} from "./actionTypes"

export const getTradeList = data => {
  return {
    type: GET_TRADE_LIST,
    payload: { data },
  }
}

export const getTradeListSuccessful = listData => {
  return {
    type: GET_TRADE_LIST_SUCCESSFUL,
    payload: listData,
  }
}

export const getTradeListFailed = error => {
  return {
    type: GET_TRADE_LIST_FAILED,
    payload: error,
  }
}
export const addTrade = user => {
  return {
    type: ADD_TRADE,
    payload: { user },
  }
}

export const addTradeSuccessful = user => {
  return {
    type: ADD_TRADE_SUCCESSFUL,
    payload: user,
  }
}

export const addTradeFailed = error => {
  return {
    type: ADD_TRADE_FAILED,
    payload: error,
  }
}
export const getEnterpriseTradeList = search => {
  return {
    type: GET_ENTERPRISE_TRADE_LIST,
    payload: { search },
  }
}
export const getEnterpriseTradeListSuccessful = listData => {
  return {
    type: GET_ENTERPRISE_TRADE_LIST_SUCCESSFUL,
    payload: listData,
  }
}
export const getEnterpriseTradeListFailed = error => {
  return {
    type: GET_ENTERPRISE_TRADE_LIST_FAILED,
    payload: error,
  }
}
