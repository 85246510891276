/**
 * @Author: ZEESHAN AHMAD
 * @Date:   2021-11-01 01:11:41
 * @Last Modified by:   ZEESHAN AHMAD
 * @Last Modified time: 2021-11-03 04:30:58
 */
import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import {
  MAKE_APPOINTMENT,
  REFER_APPOINTMENT,
  POST_APPOINTMENT_IMAGES,
  POST_APPOINTMENT_PDF,
  SEND_APPOINTMENT,
  SEND_APPOINTMENT_DETAIL,
  ACCEPT_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_DETAILS,
  GET_INACTIVE_APPOINTMENTS,
  REJECT_APPOINTMENT,
} from "./actionTypes";
import {
  appointmentdataSuccessful,
  appointmentdeletedataSuccessful,
  appointmentSuccessful,
  appointmentFailed,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
// Api's
import {
  getAppointment,
  referAppointmetToPartner,
  downloadAppointmentPdf,
  downloadAppointmentImg,
  sendAppointmentCust,
  appointmentAccepted,
  sendAppointmentCustomer,
  getAppointments,
  getInactiveAppointments,
  editClientData,
  getAppointmentDetails,
  appointmentRejected,
} from "../../helpers/fakebackend_helper";
import download from "downloadjs";
// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* getAppointmentBook({
  payload: { data, mediaFiles, files, history },
}) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  if (files != null) {
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
  }

  formData.append("mediaFiles", JSON.stringify(mediaFiles));
  try {
    const response = yield call(getAppointment, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response) {
      yield put(appointmentSuccessful(response.message));

      setTimeout(() => {
        if (
          window.location.pathname.includes(
            "service-admin-edit-appointment-details"
          )
        ) {
          history.push("/service-admin-appointments");
        } else {
          history.push("/technician-appointments");
        }
      }, 2000);
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      history.push("/login");
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* getAppointmentsBook({ payload: { search } }) {
  try {
    const response = yield call(getAppointments, search);

    if (response) {
      yield put(appointmentdataSuccessful(response));
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      // window.location = "/login"
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* getInactiveAppointmentsBook({
  payload: { pageNo, pageSize, search },
}) {
  try {
    const response = yield call(
      getInactiveAppointments,
      pageNo,
      pageSize,
      search
    );
    if (response) {
      yield put(appointmentdeletedataSuccessful(response));
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login";
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* appointmentDetails({ payload: { obj } }) {
  try {
    const response = yield call(getAppointmentDetails, obj);
    if (response) {
      yield put(appointmentdataSuccessful(response.data));
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login";
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* appointmentReject({ payload: { obj, history } }) {
  try {
    const response = yield call(appointmentRejected, obj);
    if (response) {
      yield put(appointmentSuccessful(response.message));
      setTimeout(() => {
        if (
          window.location.pathname.includes(
            "service-admin-edit-appointment-details"
          )
        ) {
          history.push("/service-admin-appointments");
        } else {
          history.push("/technician-appointments");
        }
      }, 2000);
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      history.push("/login");
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* appointmentAccept({ payload: { obj, history } }) {
  try {
    const response = yield call(appointmentAccepted, obj);
    if (response) {
      yield put(appointmentSuccessful(response.message));
      setTimeout(() => {
        if (
          window.location.pathname.includes("technician-deleted-appointments")
        ) {
          history.push("/technician-appointments");
        } else {
          history.push("/service-admin-appointments");
        }
      }, 1000);
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      history.push("/login");
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* sendAppointment({ payload: { obj } }) {
  try {
    const response = yield call(sendAppointmentCustomer, obj);
    if (response) {
      yield put(appointmentSuccessful(response.message));
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login";
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* sendAppointmentData({ payload: { obj } }) {
  try {
    const response = yield call(sendAppointmentCust, obj);
    if (response) {
      yield put(appointmentSuccessful(response.data));
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login";
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* downloadPdf({ payload: { obj } }) {
  let id = obj._id;
  try {
    const response = yield call(downloadAppointmentPdf, id);

    if (response.data) {
      window.open(response.data);
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login";
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* downloadImg({ payload: { obj } }) {
  let id = obj._id;
  try {
    const response = yield call(downloadAppointmentImg, id);

    if (response) {
      window.open(response.data);
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login";
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* referAppointment({ payload: { obj } }) {
  try {
    const response = yield call(referAppointmetToPartner, obj);

    if (response) {
      yield put(appointmentSuccessful(response.data));
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login";
    }
    yield put(appointmentFailed(error.response.data.message));
  }
}

function* AppointmentSaga() {
  yield takeEvery(MAKE_APPOINTMENT, getAppointmentBook);
  yield takeEvery(GET_APPOINTMENTS, getAppointmentsBook);
  yield takeEvery(GET_INACTIVE_APPOINTMENTS, getInactiveAppointmentsBook);
  yield takeEvery(SEND_APPOINTMENT, sendAppointment);
  yield takeEvery(GET_APPOINTMENTS_DETAILS, appointmentDetails);
  yield takeEvery(REJECT_APPOINTMENT, appointmentReject);
  yield takeEvery(ACCEPT_APPOINTMENT, appointmentAccept);
  yield takeEvery(POST_APPOINTMENT_PDF, downloadPdf);
  yield takeEvery(SEND_APPOINTMENT_DETAIL, sendAppointmentData),
    yield takeEvery(POST_APPOINTMENT_IMAGES, downloadImg),
    yield takeEvery(REFER_APPOINTMENT, referAppointment);
}

export default AppointmentSaga;
