import {
  GET_ENTERPRISES,
  GET_ENTERPRISES_SUCCESSFUL,
  GET_ENTERPRISES_FAILED,
  CREATE_NEW_ENTERPRISE,
  CREATE_NEW_ENTERPRISE_SUCCESSFUL,
  CREATE_NEW_ENTERPRISE_FAILED,
  ACCESS_ACOUNT_BY_EMAIL,
  ACCESS_ACOUNT_BY_EMAIL_SUCCESSFUL,
  ACCESS_ACOUNT_BY_EMAIL_FAILED,
  ENTERPRISE_SIGNUP,
  ENTERPRISE_SIGNUP_SUCCESSFUL,
  ENTERPRISE_SIGNUP_FAILED,
  SET_THEME_COLOR,
  SET_THEME_COLOR_SUCCESSFUL,
  SET_THEME_COLOR_FAILED,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESSFUL,
  UPLOAD_LOGO_FAILED,
  TOGGLE_ENTERPRISE_STATUS,
  TOGGLE_ENTERPRISE_STATUS_SUCCESSFUL,
  TOGGLE_ENTERPRISE_STATUS_FAILED,
  TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS,
  TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS_SUCCESSFUL,
  TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS_FAILED,
  PROFILE_ENP_ERROR,
  PROFILE_ENP_SUCCESS,
  EDIT_ENPP_PROFILE,
  NEW_SUPER_ADMIN_SIGNUP,
  NEW_SUPER_ADMIN_SIGNUP_SUCESS,
  NEW_SUPER_ADMIN_SIGNUP_FAILED,
  EDIT_BILLING_INFO,
  EDIT_BILLING_INFO_SUCCESS,
  EDIT_BILLING_INFO_FAILURE,
  SET_ADMIN_PASSWORD,
  SET_ADMIN_PASSWORD_SUCCESS,
} from "./actionTypes";

export const enpEditProfile = (user, file, history) => {
  return {
    type: EDIT_ENPP_PROFILE,
    payload: { user, file, history },
  };
};

export const enpProfileSuccess = (msg) => {
  return {
    type: PROFILE_ENP_SUCCESS,
    payload: msg,
  };
};

export const enpProfileError = (error) => {
  return {
    type: PROFILE_ENP_ERROR,
    payload: error,
  };
};

export const getAllEnterprises = (pageNo, pageSize, search, data) => {
  return {
    type: GET_ENTERPRISES,
    payload: { pageNo, pageSize, search, data },
  };
};

export const getEnterprisesSucess = (user) => {
  return {
    type: GET_ENTERPRISES_SUCCESSFUL,
    payload: user,
  };
};

export const getEnterprisesFailed = (error) => {
  return {
    type: GET_ENTERPRISES_FAILED,
    payload: error,
  };
};
export const createNewEnterprise = (data, history) => {
  return {
    type: CREATE_NEW_ENTERPRISE,
    payload: { data, history },
  };
};
export const createNewEnterpriseSuccess = (data) => {
  return {
    type: CREATE_NEW_ENTERPRISE_SUCCESSFUL,
    payload: data,
  };
};
export const createNewEnterpriseFailed = (error) => {
  return {
    type: CREATE_NEW_ENTERPRISE_FAILED,
    payload: error,
  };
};
export const accessAcountByEmail = (email, history) => {
  return {
    type: ACCESS_ACOUNT_BY_EMAIL,
    payload: { email, history },
  };
};
export const accessAcountByEmailSuccess = (data) => {
  return {
    type: ACCESS_ACOUNT_BY_EMAIL_SUCCESSFUL,
    payload: data,
  };
};
export const accessAcountByEmailFailed = (error) => {
  return {
    type: ACCESS_ACOUNT_BY_EMAIL_FAILED,
    payload: error,
  };
};
export const enterpriseSignup = (data, history) => {
  return {
    type: ENTERPRISE_SIGNUP,
    payload: { data, history },
  };
};
export const enterpriseSignupSuccess = (data) => {
  return {
    type: ENTERPRISE_SIGNUP_SUCCESSFUL,
    payload: data,
  };
};
export const enterpriseSignupFailed = (error) => {
  return {
    type: ENTERPRISE_SIGNUP_FAILED,
    payload: error,
  };
};

export const setThemeColor = (data, history) => {
  return {
    type: SET_THEME_COLOR,
    payload: { data, history },
  };
};
export const setThemeColorSuccess = (data) => {
  return {
    type: SET_THEME_COLOR_SUCCESSFUL,
    payload: data,
  };
};
export const setThemeColorFailed = (error) => {
  return {
    type: SET_THEME_COLOR_FAILED,
    payload: error,
  };
};
export const uploadLogo = (data, file, history) => {
  return {
    type: UPLOAD_LOGO,
    payload: { data, file, history },
  };
};
export const uploadLogoSuccess = (data) => {
  return {
    type: UPLOAD_LOGO_SUCCESSFUL,
    payload: data,
  };
};
export const uploadLogoFailed = (error) => {
  return {
    type: UPLOAD_LOGO_FAILED,
    payload: error,
  };
};

export const toggleEnterPriseStatus = (data, history, cb) => {
  return {
    type: TOGGLE_ENTERPRISE_STATUS,
    payload: { data, history, cb },
  };
};
export const toggleEnterPriseStatusSuccess = (data) => {
  return {
    type: TOGGLE_ENTERPRISE_STATUS_SUCCESSFUL,
    payload: data,
  };
};
export const toggleEnterPriseStatusFailed = (error) => {
  return {
    type: TOGGLE_ENTERPRISE_STATUS_FAILED,
    payload: error,
  };
};
export const toggleEnterprisePreferredPartnerStatus = (data, history, cb) => {
  return {
    type: TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS,
    payload: { data, history, cb },
  };
};
export const toggleEnterprisePreferredPartnerStatusSuccess = (data) => {
  return {
    type: TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS_SUCCESSFUL,
    payload: data,
  };
};
export const toggleEnterprisePreferredPartnerStatusFailed = (error) => {
  return {
    type: TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS_FAILED,
    payload: error,
  };
};

export const setPassword = (data) => {
  return { type: SET_ADMIN_PASSWORD, payload: { data } };
};

export const setPasswordSuccess = (data) => {
  return { type: SET_ADMIN_PASSWORD_SUCCESS, payload: data };
};
export const signupNewSuperAdmin = (data, file, history) => {
  return {
    type: NEW_SUPER_ADMIN_SIGNUP,
    payload: { data, file, history },
  };
};
export const signupNewSuperAdminSuccess = (data) => {
  return {
    type: NEW_SUPER_ADMIN_SIGNUP_SUCESS,
    payload: data,
  };
};
export const signupNewSuperAdminFailed = (error) => {
  return {
    type: NEW_SUPER_ADMIN_SIGNUP_FAILED,
    payload: error,
  };
};

export const editBillingInfo = (data, history) => {
  return {
    type: EDIT_BILLING_INFO,
    payload: { data, history },
  };
};
export const editBillingInfoSuccessful = (data) => {
  return {
    type: EDIT_BILLING_INFO_SUCCESS,
    payload: data,
  };
};
export const editBillingInfoFailed = (error) => {
  return {
    type: EDIT_BILLING_INFO_FAILURE,
    payload: error,
  };
};
