import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  INVITE_NEW_SUPER_ADMIN,
  INVITE_NEW_SUPER_ADMIN_SUCCESS,
  INVITE_NEW_SUPER_ADMIN_FAILED,
  GET_NEW_SUPER_LINK,
  GET_NEW_SUPER_LINK_FAILED,
  GET_NEW_SUPER_LINK_SUCCESS,
  NEW_SUPER_ADMIN_SIGNUP,
  NEW_SUPER_ADMIN_SIGNUP_SUCESS,
  NEW_SUPER_ADMIN_SIGNUP_FAILED,
  RESET_MESSAGE,
} from "./actionTypes";

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  inviteSuccess: null,
  inviteFailed: null,
  getLinkInfoSucess: null,
  getLinkInfoFailed: null,
  user: null,
  signupSuccess: null,
  signupFailed: null,
  linkData: null,
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case RESET_MESSAGE:
      state = { ...state, loading: false, inviteSuccess: null, inviteFailed: null };
      break;
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      };
      break;
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      };
      break;
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      };
      break;
    case INVITE_NEW_SUPER_ADMIN:
      state = {
        ...state,
        loading: true,
        inviteSuccess: null,
        inviteFailed: null,
      };
      break;
    case INVITE_NEW_SUPER_ADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        inviteSuccess: action.payload,
      };
      break;
    case INVITE_NEW_SUPER_ADMIN_FAILED:
      state = {
        ...state,
        loading: false,
        inviteFailed: action.payload,
      };
      break;

    case GET_NEW_SUPER_LINK:
      state = {
        ...state,
        loading: true,
        getLinkInfoSucess: null,
        getLinkInfoFailed: null,
      };
      break;
    case GET_NEW_SUPER_LINK_SUCCESS:
      state = {
        ...state,
        loading: false,
        getLinkInfoFailed: null,
        user: action.payload,
      };
      break;
    case GET_NEW_SUPER_LINK_FAILED:
      state = {
        ...state,
        loading: false,
        getLinkInfoSucess: null,
        getLinkInfoFailed: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default account;
