import {
  ADD_NEW_TEMPLATE,
  ADD_NEW_TEMPLATE_SUCCESSFUL,
  ADD_NEW_TEMPLATE_FAILED,
  VIEW_SAVED_TEMPLATES,
  VIEW_SAVED_TEMPLATES_SUCCESS,
  VIEW_SAVED_TEMPLATES_FAILED,
  LOAD_TEMPLATE_FAILURE,
  LOAD_TEMPLATE,
  LOAD_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_FAILURE,
} from "./actionTypes"

export const addNewTemplate = (obj, history) => {
  return {
    type: ADD_NEW_TEMPLATE,
    payload: { obj, history },
  }
}

export const addNewTemplateSuccess = message => {
  return {
    type: ADD_NEW_TEMPLATE_SUCCESSFUL,
    payload: message,
  }
}

export const addNewTemplateFailed = error => {
  return {
    type: ADD_NEW_TEMPLATE_FAILED,
    payload: error,
  }
}

export const viewSavedTemplates = () => {
  return {
    type: VIEW_SAVED_TEMPLATES,
  }
}

export const viewSavedTemplatesSuccess = data => {
  return {
    type: VIEW_SAVED_TEMPLATES_SUCCESS,
    payload: data,
  }
}

export const viewSavedTemplatesFailed = error => {
  return {
    type: VIEW_SAVED_TEMPLATES_FAILED,
    payload: error,
  }
}

export const loadTemplate = id => {
  return {
    type: LOAD_TEMPLATE,
    payload: { id },
  }
}

export const loadTemplateSuccess = template => {
  return {
    type: LOAD_TEMPLATE_SUCCESS,
    payload: template,
  }
}

export const loadTemplateFailed = error => {
  return {
    type: LOAD_TEMPLATE_FAILURE,
    payload: error,
  }
}
export const editTemplate = (id, obj, history) => {
  return {
    type: EDIT_TEMPLATE,
    payload: { id, obj, history },
  }
}
export const editTemplateSuccess = message => {
  return {
    type: EDIT_TEMPLATE_SUCCESS,
    payload: message,
  }
}
export const editTemplateFailed = error => {
  return {
    type: EDIT_TEMPLATE_FAILURE,
    payload: error,
  }
}
