import {
  GET_LIST,
  GET_LIST_SUCCESSFUL,
  GET_LIST_FAILED,
  GET_ARCHIVED_LIST,
  GET_ARCHIVED_LIST_SUCCESSFUL,
  GET_ARCHIVED_LIST_FAILED,
} from "./actionTypes"

const initialState = {
  listError: null,
  listData: [],
  ArchivedlistData: [],
  loading: false,
}

const PreferredParttners = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST:
      state = {
        ...state,
        loading: true,
        creationError: null,
      }
      break
    case GET_LIST_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        listData: action.payload,
      }
      break
    case GET_LIST_FAILED:
      state = {
        ...state,
        loading: false,
        listError: action.payload,
      }
      break
    case GET_ARCHIVED_LIST:
      state = {
        ...state,
        loading: true,
        creationError: null,
      }
      break
    case GET_ARCHIVED_LIST_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        ArchivedlistData: action.payload,
      }
      break
    case GET_ARCHIVED_LIST_FAILED:
      state = {
        ...state,
        loading: false,
        listError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PreferredParttners
