import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { ADD_PARTNER } from "./actionTypes"
import { addPartnerSuccessful, addPartnerFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { createPartner } from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* addPartner({ payload: { user, file, profilePic, trades, history } }) {
  const formData = new FormData()
  Object.keys(user).forEach(key => {
    formData.append(key, user[key])
  })
  formData.append("insurance", file)
  formData.append("profilePic", profilePic)
  formData.append("trades", JSON.stringify(trades))

  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(createPartner, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      if (response) {
        yield put(addPartnerSuccessful(response.message))

        setTimeout(() => {
          history.push("/preferred-partner-list")
        }, 1000)
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      history.push("/login")
    }
    yield put(addPartnerFailed(error.response.data.message))
  }
}

export function* watchUserRegister() {
  yield takeEvery(ADD_PARTNER, addPartner)
}

function* AddPreferredParttnerSaga() {
  yield all([fork(watchUserRegister)])
}

export default AddPreferredParttnerSaga
