import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

// users
import user1 from "../../assets/images/login/user-icon.png";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const history = useHistory();
  const [username, setusername] = useState("admin");
  const [profilePic, setUserProfilePic] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = localStorage.getItem("authUser");
        const profilePic = localStorage.getItem("user");
        setUserProfilePic(JSON.parse(profilePic).profilePic);
        const username = "Welcome, " + obj + "!";
        setusername(username);
      }
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <div className="text-end">
        <span
          className="d-none d-xl-inline-block ms-2 me-1"
          style={{ fontSize: "1rem" }}
        >
          {username}
        </span>
        <img
          className="rounded-circle header-profile-user ml-2"
          src={
            userData.profilePic
              ? `${process.env.REACT_APP_API_DOMAIN}/${userData.profilePic}`
              : user1
          }
          alt="Header Avatar"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(`/super/super-admin-setting`)}
        />
      </div>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
