import {
  CREATE_TECHNICIAN,
  CREATE_TECHNICIAN_SUCCESSFUL,
  CREATE_TECHNICIAN_FAILED,
  EDIT_TECHNICIAN,
  EDIT_TECHNICIAN_SUCCESSFUL,
  EDIT_TECHNICIAN_FAILED,
} from "./actionTypes"

const initialState = {
  error: null,
  message: null,
  loading: false,
  user: null,
}

const Technician = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TECHNICIAN:
      state = {
        ...state,
        loading: true,
        message: null,
      }
      break
    case CREATE_TECHNICIAN_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
        error: null,
      }
      break
    case CREATE_TECHNICIAN_FAILED:
      state = {
        ...state,
        loading: false,
        message: null,
        error: action.payload,
      }
      break
      case EDIT_TECHNICIAN:
      state = {
        ...state,
        loading: true,
        message: action.payload,
      }
      break
    case EDIT_TECHNICIAN_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
        error: null,
      }
      break
    case EDIT_TECHNICIAN_FAILED:
      state = {
        ...state,
        message: null,
        error: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Technician
