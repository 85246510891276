import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"

//Account Redux states
import { CREATE_USER } from "./actionTypes"
import { createUserSuccessful, createUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { createUserData } from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* createUser({ payload: { user, file, history } }) {
  const formData = new FormData()
  Object.keys(user).forEach(key => {
    formData.append(key, user[key])
  })
  formData.append("profilePic", file)

  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(createUserData, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      if (response) {
        yield put(createUserSuccessful(response.message))
        if (window.location.pathname.includes("service-admin-edit-setting")) {
          setTimeout(() => {
            history.push("/service-admin-setting")
          }, 2000)
        } else {
          setTimeout(() => {
            history.push("/admin-list")
          }, 2000)
        }
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(createUserFailed(error.response.data.message))
  }
}

export function* watchUserRegister() {
  yield takeEvery(CREATE_USER, createUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
