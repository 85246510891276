import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import AdminSaga from "./serviceadmin/addserviceadmin/saga"
import AdminListSaga from "./serviceadmin/serviceadminlist/saga"
import PreferredParttnersListSaga from "./preferredpartner/list/saga"
import AddPreferredParttnerSaga from "./preferredpartner/add/saga"
import TradesSaga from "./trades/add/saga"
import TradesListSaga from "./trades/list/saga"
import ProfileDataSaga from "./getProfile/saga"
import ToggleSaga from "./toggleStatus/saga"
import changePasswordSaga from "./auth/changepwd/saga"
import TradesDeleteSaga from "./trades/delete/saga"
import TechnicianSaga from "./technician/addtechnician/saga"
import TechnicianListSaga from "./technician/technicianlist/saga"
import AppointmentSaga from "./makeappointment/saga"
import TechAdminSaga from "./technician/dashboard/saga"
import EmailTemplateSaga from "./emailTemplate/saga"
import EnterprisesListSaga from "./enterprise/saga"
// import ecommerceSaga from "./e-commerce/saga"
// import calendarSaga from "./calendar/saga"
// import chatSaga from "./chat/saga"
// import cryptoSaga from "./crypto/saga"
// import invoiceSaga from "./invoices/saga"
// import projectsSaga from "./projects/saga"
// import mailsSaga from "./mails/saga"
// import tasksSaga from "./tasks/saga"
// import contactsSaga from "./contacts/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    changePasswordSaga(),
    LayoutSaga(),
    EmailTemplateSaga(),
    AdminSaga(),
    AdminListSaga(),
    PreferredParttnersListSaga(),
    AddPreferredParttnerSaga(),
    TradesSaga(),
    TradesListSaga(),
    ProfileDataSaga(),
    ToggleSaga(),
    TechAdminSaga(),
    TradesDeleteSaga(),
    TechnicianSaga(),
    TechnicianListSaga(),
    AppointmentSaga(),
    EnterprisesListSaga(),
    // fork(ecommerceSaga),
    // fork(calendarSaga),
    // fork(chatSaga),
    // fork(cryptoSaga),
    // fork(invoiceSaga),
    // fork(projectsSaga),
    // fork(mailsSaga),
    // fork(tasksSaga),
    // fork(contactsSaga),
  ])
}
