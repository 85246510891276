import {
  GET_ADMIN_LIST,
  GET_ADMIN_LIST_SUCCESSFUL,
  GET_ADMIN_LIST_FAILED,
  GET_DASHBOARD_COUNT_FAILURE,
  GET_DASHBOARD_COUNT_SUCCESS,
  GET_DASHBORD_COUNT,
} from "./actionTypes"

const initialState = {
  listError: null,
  adminListData: [],
  loading: false,
  dashboardCount: [],
}

const Admins = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_LIST:
      state = {
        ...state,
        loading: true,
        creationError: null,
      }
      break
    case GET_ADMIN_LIST_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        adminListData: action.payload,
      }
      break
    case GET_ADMIN_LIST_FAILED:
      state = {
        ...state,
        loading: false,
        listError: action.payload,
      }
      break

    case GET_DASHBORD_COUNT:
      state = {
        ...state,
        loading: true,
        creationError: null,
      }
      break
    case GET_DASHBOARD_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        dashboardCount: action.payload,
      }
      break
    case GET_DASHBOARD_COUNT_FAILURE:
      state = {
        ...state,
        loading: false,
        listError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Admins
