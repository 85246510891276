import {
  ADD_PARTNER,
  ADD_PARTNER_SUCCESSFUL,
  ADD_PARTNER_FAILED,
} from "./actionTypes"

const initialState = {
  partnerError: null,
  message: null,
  loading: false,
  user: null,
}

const PreferredPartner = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PARTNER:
      state = {
        ...state,
        loading: true,
        partnerError: null,
      }
      break
    case ADD_PARTNER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        partnerError: null,
      }
      break
    case ADD_PARTNER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        partnerError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PreferredPartner
