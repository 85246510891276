import {
  CREATE_TECHNICIAN,
  CREATE_TECHNICIAN_SUCCESSFUL,
  CREATE_TECHNICIAN_FAILED,
  EDIT_TECHNICIAN,
  EDIT_TECHNICIAN_SUCCESSFUL,
  EDIT_TECHNICIAN_FAILED,
  API_ERROR,
} from "./actionTypes"

export const createTechnician = (user, file, trades, history) => {
  return {
    type: CREATE_TECHNICIAN,
    payload: { user, file, trades, history },
  }
}

export const createTechnicianSuccessful = msg => {
  return {
    type: CREATE_TECHNICIAN_SUCCESSFUL,
    payload: msg,
  }
}

export const createTechnicianFailed = error => {
  return {
    type: CREATE_TECHNICIAN_FAILED,
    payload: error,
  }
}

export const editTechnician = user => {
  return {
    type: EDIT_TECHNICIAN,
    payload: { user },
  }
}

export const editTechnicianSuccessful = user => {
  return {
    type: EDIT_TECHNICIAN_SUCCESSFUL,
    payload: user,
  }
}

export const editTechnicianFailed = error => {
  return {
    type: EDIT_TECHNICIAN_FAILED,
    payload: error,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
