import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { ADD_TRADE } from "./actionTypes"
import { addTradeSuccessful, addTradeFailed } from "./actions"
import { addTradeData } from "../../../helpers/fakebackend_helper"
function* addTrade({ payload: { user, history, cb } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(addTradeData, user)
      if (response) {
        yield put(addTradeSuccessful(response.message))
        return cb(true)
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    console.log(error)
    if (error.response) {
      yield put(addTradeFailed(error.response.data.message))
    } else {
      yield put(addTradeFailed(error.message))
    }
  }
}

export function* watchUserRegister() {
  yield takeEvery(ADD_TRADE, addTrade)
}

function* TradesSaga() {
  yield all([fork(watchUserRegister)])
}

export default TradesSaga
