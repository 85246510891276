import React from "react"
import logo from "../src/assets/images/no-internet.png"

function NoInternet() {
  return (
    <div className="error-card">
      <div className="no-internet__width">
        <div>
          <img src={logo} />
        </div>
        <h2 style={{ lineHeight: "normal" }} className="no_internet_h2">
          Oops! No Connection Found.
        </h2>
        <p style={{ fontSize: "14px" }} className=".no_internet_p ">
          Please check your internet connection and try again.
        </p>
      </div>
    </div>
  )
}

export default NoInternet
