/**
 * @Author: ZEESHAN AHMAD
 * @Date:   2021-11-01 01:11:41
 * @Last Modified by:   ZEESHAN AHMAD
 * @Last Modified time: 2021-11-04 10:26:34
 */
import {
  GET_TECHNICIAN_LIST,
  GET_TECHNICIAN_LIST_SUCCESSFUL,
  GET_TECHNICIAN_LIST_FAILED,
} from "./actionTypes"

export const getTechniciansList = (pageNo, pageSize, search, data) => {
  return {
    type: GET_TECHNICIAN_LIST,
    payload: { pageNo, pageSize, search, data },
  }
}

export const getTechniciansListSuccessful = adminListData => {
  return {
    type: GET_TECHNICIAN_LIST_SUCCESSFUL,
    payload: adminListData,
  }
}

export const getTechniciansListFailed = error => {
  return {
    type: GET_TECHNICIAN_LIST_FAILED,
    payload: error,
  }
}
