import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import "react-drawer/lib/react-drawer.css"
import EnterpriseProfileMenu from "components/Common/EnterpriseProfileMenu"
import { Container } from "reactstrap"

const Header = props => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const [position, setPosition] = useState()
  const [open, setOpen] = useState(false)

  function toggleRightDrawer() {
    setPosition("right")
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false)
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <Container fluid="xxl">
        <div className="navbar-header">
          <div className="header">
            <div className="text-start d-flex align-items-center">
              <button
                type="button"
                onClick={() => {
                  tToggle()
                }}
                className="btn btn-sm font-size-16 wd-12 menu-button"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars" />
              </button>

              <h4 className="mb-0 mt-1" style={{ textTransform: "capitalize" }}>
                {props.name??''}
              </h4>
            </div>
            <EnterpriseProfileMenu />
          </div>
        </div>
        </Container>
      </header>
    </React.Fragment>
  )
}

export default Header
