export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull";
export const REGISTER_USER_FAILED = "register_user_failed";
export const INVITE_NEW_SUPER_ADMIN = "INVITE_NEW_SUPER_ADMIN";
export const INVITE_NEW_SUPER_ADMIN_SUCCESS = "INVITE_NEW_SUPER_ADMIN_SUCCESS";
export const INVITE_NEW_SUPER_ADMIN_FAILED = "INVITE_NEW_SUPER_ADMIN_FAILED";
export const GET_NEW_SUPER_LINK = "GET_NEW_SUPER_LINK";
export const GET_NEW_SUPER_LINK_SUCCESS = "GET_NEW_SUPER_LINK_SUCCESS";
export const GET_NEW_SUPER_LINK_FAILED = "GET_NEW_SUPER_LINK_FAILED";
export const RESET_MESSAGE = "RESET_MESSAGE";
