import {
  GET_ADMIN_LIST,
  GET_ADMIN_LIST_SUCCESSFUL,
  GET_ADMIN_LIST_FAILED,
  GET_DASHBORD_COUNT,
  GET_DASHBOARD_COUNT_FAILURE,
  GET_DASHBOARD_COUNT_SUCCESS,
} from "./actionTypes"

export const getAdminList = (pageNo, pageSize, search, data) => {
  return {
    type: GET_ADMIN_LIST,
    payload: { pageNo, pageSize, search, data },
  }
}

export const getAdminListSuccessful = adminListData => {
  return {
    type: GET_ADMIN_LIST_SUCCESSFUL,
    payload: adminListData,
  }
}

export const getAdminListFailed = error => {
  return {
    type: GET_ADMIN_LIST_FAILED,
    payload: error,
  }
}
export const getDashboardCount = () => {
  return {
    type: GET_DASHBORD_COUNT,
  }
}

export const DashboardCountSuccess = data => {
  return {
    type: GET_DASHBOARD_COUNT_SUCCESS,
    payload: data,
  }
}

export const dashboardCountFailure = error => {
  return {
    type: GET_DASHBOARD_COUNT_FAILURE,
    payload: error,
  }
}
