import React from "react"
import Loader from "../src/assets/images/loader.svg"
// import Fixing from "../"
import Fixing from "../src/assets/images/fixing.png"

function Preloader() {
  return (
    <div className="error-card">
      <div className="w-100">
        <div>
          {/*<img src={Fixing} />*/}
        </div>
        <div className="preloadericon"> 
          {/* <img src={Loader} width="100" /> */}
          {/* <object type="image/svg+xml" data={Loader} width="100">
            
          </object> */}
          <div className="loader11"></div>
        </div>
        {/*<p className="no_internet_p">Fixing up the page for you...</p>*/}
      </div>
    </div>
  )
}

export default Preloader
