import {
  TOGGLE_STATUS,
  TECH_TOGGLE_STATUS,
  TOGGLE_SUCCESSFUL,
  TOGGLE_FAILED,
  ARCHIVED_STATUS,
  ARCHIVED_SUCCESSFUL,
  ARCHIVED_FAILED,
  DELETE_PARTNER,
} from "./actionTypes"

export const getToggle = (data, history) => {
  return {
    type: TOGGLE_STATUS,
    payload: { data, history },
  }
}

export const getTechToggle = (data, history) => {
  return {
    type: TECH_TOGGLE_STATUS,
    payload: { data, history },
  }
}

export const getdeletePartner = data => {
  return {
    type: DELETE_PARTNER,
    payload: { data },
  }
}

export const toggleSuccessful = msg => {
  return {
    type: TOGGLE_SUCCESSFUL,
    payload: msg,
  }
}

export const toggleFailed = error => {
  return {
    type: ARCHIVED_FAILED,
    payload: error,
  }
}

export const getArchived = data => {
  return {
    type: ARCHIVED_STATUS,
    payload: { data },
  }
}
export const archivedSuccessful = data => {
  return {
    type: ARCHIVED_SUCCESSFUL,
    payload: data,
  }
}

export const archivedFailed = error => {
  return {
    type: TOGGLE_FAILED,
    payload: error,
  }
}
