import {
  MAKE_APPOINTMENT,
  MAKE_APPOINTMENT_SUCCESSFUL,
  MAKE_APPOINTMENT_FAILED,
  GET_APPOINTMENTS,
  GET_INACTIVE_APPOINTMENTS,
  GET_APPOINTMENTS_DETAILS,
  GET_APPOINTMENTS_DATA_SUCCESSFUL,
  GET_APPOINTMENTS_SUCCESSFUL,
  GET_APPOINTMENTS_FAILED,
  SEND_APPOINTMENT_DETAIL,
  SEND_APPOINTMENT,
  POST_APPOINTMENT_IMAGES,
  POST_APPOINTMENT_PDF,
  REFER_APPOINTMENT,
  REJECT_APPOINTMENT,
  ACCEPT_APPOINTMENT,
  REJECT_APPOINTMENT_SUCCESSFUL,
  REJECT_APPOINTMENT_FAILED,
  GET_DELETE_APPOINTMENTS_DATA_SUCCESSFUL,
} from "./actionTypes"

const initialState = {
  appintment: {},
  appointmentError: null,
  loading: false,
  appointmentSuccess: null,
  success: null,
  appintments: [],
  deleteappintments: [],
  data: {},
}

const Appointment = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_APPOINTMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case MAKE_APPOINTMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      }
      break
    case MAKE_APPOINTMENT_FAILED:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_APPOINTMENTS_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_APPOINTMENTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case SEND_APPOINTMENT_DETAIL:
      state = {
        ...state,
        loading: true,
      }
      break
    case SEND_APPOINTMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case POST_APPOINTMENT_IMAGES:
      state = {
        ...state,
        loading: true,
      }
      break
    case POST_APPOINTMENT_PDF:
      state = {
        ...state,
        loading: true,
      }
      break
    case REFER_APPOINTMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_INACTIVE_APPOINTMENTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_APPOINTMENTS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        appointmentSuccess: action.payload,
        appointmentError: null,
      }

      break
    case GET_APPOINTMENTS_DATA_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        appintments: action.payload,
      }
      break
    case GET_DELETE_APPOINTMENTS_DATA_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        deleteappintments: action.payload,
      }
      break
    case GET_APPOINTMENTS_FAILED:
      state = {
        ...state,
        loading: false,
        appointmentSuccess: null,
        appointmentError: action.payload,
      }
      break
    case REJECT_APPOINTMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case ACCEPT_APPOINTMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case REJECT_APPOINTMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      }
      break
    case REJECT_APPOINTMENT_FAILED:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Appointment
