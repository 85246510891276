import {
  GET_TRADE_LIST,
  GET_TRADE_LIST_SUCCESSFUL,
  GET_TRADE_LIST_FAILED,
  GET_ENTERPRISE_TRADE_LIST,
  GET_ENTERPRISE_TRADE_LIST_SUCCESSFUL,
  GET_ENTERPRISE_TRADE_LIST_FAILED,
} from "./actionTypes"

const initialState = {
  listError: null,
  listData: [],
  loading: false,
  enterpriseTradeList: [],
  enterpriseTradeListError: null,
  enterpriseTradeListLoading: false,
}

const TradesList = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRADE_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_TRADE_LIST_SUCCESSFUL:
      state = {
        ...state,
        listData: action.payload,
        loading: false,
      }
      break
    case GET_TRADE_LIST_FAILED:
      state = {
        ...state,
        loading: false,
        listError: action.payload,
      }
      break
    case GET_ENTERPRISE_TRADE_LIST:
      state = {
        ...state,
        enterpriseTradeListLoading: true,
      }
      break
    case GET_ENTERPRISE_TRADE_LIST_SUCCESSFUL:
      state = {
        ...state,
        enterpriseTradeList: action.payload,
        enterpriseTradeListLoading: false,
      }
      break
    case GET_ENTERPRISE_TRADE_LIST_FAILED:
      state = {
        ...state,
        enterpriseTradeListLoading: false,
        enterpriseTradeListError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default TradesList
