/**
 * @Author: ZEESHAN AHMAD
 * @Date:   2021-11-01 01:11:41
 * @Last Modified by:   ZEESHAN AHMAD
 * @Last Modified time: 2021-11-04 10:54:53
 */
import axios from "axios";
import { del, get, post, formpost, put, downloadpost } from "./api_helper";
import { postLogin } from "./login_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = (data) => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = (data) => post(url.POST_FAKE_LOGIN, data);

// postForgetPwd
const postFakeForgetPwd = (data) => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = (data) => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = (data) => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = (data) => post(url.POST_FAKE_LOGIN, data);
const createUserData = (data) => formpost(url.POST_USER, data);
const getadminData = (obj) => post(url.GET_ADMIN_LIST, obj);
export const getArchiveListData = (pageNo, pageSize, search) =>
  get(`${url.GET_ARCHIVE_LIST}/${pageNo}/${pageSize}/${search}`);
export const getData = (obj) => post(url.GET_LIST, obj);
export const createPartner = (data, config) => formpost(url.ADD_PARTNER, data, config);
const getTradeData = (data) => post(`${url.GET_Trade_LIST}`, data);
export const getEntpTradeData = (search) =>
  get(`${url.GET_ENTERPRISE_TRADE_LIST}/1/99?search=${search}`);
export const addTradeData = (data, config) => post(url.ADD_TRADE, data, config);
const getProfileData = (data, config) => post(url.GET_PROFILE_LIST, data, config);
export const getToggle = (data, config) => post(url.GET_TOGGLE, data, config);
export const getTechnicianToggle = (data, config) => post(url.GET_TECH_TOGGLE, data, config);
export const getDelete = (data, config) => post(url.GET_DELETE, data, config);
export const getArchived = (data, config) => post(url.GET_ARCHIVED, data, config);
export const changepwd = (data) => post(url.PASSWORD_FORGET, data);
export const tradedelete = (data) => post(url.DEL_TRADE, data);
export const editProfileApi = (data) => formpost(url.EDIT_PROFILE, data);
export const getAppointment = (data) => formpost(url.GET_APPOINTMENT, data);
export const getAppointments = (search) => get(`${url.GET_APPOINTMENTS}/1/99?search=${search}`);
export const getAppointmentDetails = (obj) => post(url.GET_APPOINTMENT_DATA, obj);
export const editClientData = (data) => formpost(url.EDIT_CLIENT, data);
export const savePaymentdetails = (data) => post(url.SAVE_PAYMENT, data);
export const saveclientDetails = (data) => post(url.SAVE_CLIENT_DETAILS, data);
export const signUp = (data, config) => formpost(url.REGISTER_PARTNER, data, config);
export const varifyPartner = (data, config) => post(url.VARIFY_PARTNER, data, config);
export const otpVarification = (data, config) => post(url.VARIFY_OTP, data, config);
export const getPartnerData = (data) => post(url.GET_PARTNER_DATA, data);
export const createTechnicianData = (data) => formpost(url.POST_TECHNICIAN, data);
export const getTechniciansData = (pageNo, pageSize, search, data) =>
  post(`${url.GET_TECHNICIAN_LIST}/${pageNo}/${pageSize}?search=${search}`, data);
export const appointmentRejected = (obj) => post(url.REJECT_APPOINTMENT, obj);
export const appointmentAccepted = (obj) => post(url.ACCEPT_APPOINTMENT, obj);
export const getInactiveAppointments = (pageNo, pageSize, search) =>
  get(`${url.GET_INACTIVE_APPOINTMENTS}/${pageNo}/${pageSize}?search=${search}`);
export const getClientData = (data) => post(url.GET_CLIENT_DATA, data);
export const sendAppointmentCust = (data) => post(url.SEND_APPOINTMENT, data);
export const sendAppointmentCustomer = (data) => post(url.SEND_APPOINTMENT_CUSTOMER, data);
export const downloadAppointmentPdf = (data) => get(`${url.DOWNLOAD_APPOINTMENT_PDF}/${data}`);
export const downloadAppointmentImg = (data) => get(`${url.DOWNLOAD_APPOINTMENT_IMAGES}/${data}`);
export const referAppointmetToPartner = (obj) => post(url.REFER_APPOINTMENT_TO_PARTNER, obj);

// postForgetPwd
const postJwtForgetPwd = (data) => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data) => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = (id) => get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = (event) => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = (event) => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = (event) => del(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const addMessage = (message) => post(url.ADD_MESSAGE, message);

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = (order) => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = (order) => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = (order) => del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = (customer) => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = (customer) => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = (customer) => del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = (id) => get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = (id) =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

// get contacts
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = (user) => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = (user) => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = (user) => del(url.DELETE_USER, { headers: { user } });

/** PROJECT */
// add user
export const addNewProject = (project) => post(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = (project) => put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = (project) => del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS);

// add inboxmail
export const addNewInboxMail = (inboxmail) => post(url.ADD_NEW_INBOX_MAIL, inboxmail);

// delete inboxmail
export const deleteInboxMail = (inboxmail) =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } });

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS);

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS);

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS);

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS);

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS);
//GET SERVICE ADMIN DATASHBOARD DATA
export const getDashboardCount = () => get(url.GET_DASHBOARD_COUNT);
// email templates
export const saveNewTemplate = (obj) => post(url.UPSERT_EMAIL_TEMPLATE, obj);
export const getAdminSavedTemplates = () => get(url.GET_ALL_SAVED_TEMPLATES);
export const getTemplate = (id) => post(url.LOAD_TEMPLATE, id);
// enterprise
export const getEnterprises = (pageNo, pageSize, search) =>
  get(`${url.GET_ENTERPRISE_LIST}/${pageNo}/${pageSize}?search=${search}`);
export const createEnterprise = (data) => post(url.CREATE_ENTERPRISE, data);
export const getEnterPriseAccountByMail = (email) => post(url.ACCESS_ENTERPRISE_ACCOUNT, email);
export const enterpriseSignupApi = (data) => post(url.ENTERPRISE_SIGNUP, data);
export const uploadEnterpriseLogo = (data) => formpost(url.UPLOAD_LOGO, data);
export const setThemeColor = (data) => post(url.ADD_THEME_COLOR, data);
export const toggleEnterprisePartnerStatus = (data) =>
  post(url.TOGGLE_ENTERPRISE_PARTNER_STATUS, data);
export const toggleEnterPriseStatus = (data) => post(url.TOOGLE_ENTERPRISE_STATUS, data);
export const sendNewSuperLink = (data) => post(url.INVITE_NEW_SUPER_ADMIN, data);
export const getNewSuperLinkInfo = (data) => post(url.GET_NEW_SUPER_INVITE_LINK_INFO, data);
export const newSuperSignup = (data) => formpost(url.NEW_SUPER_ADMIN_SIGNUP, data);
export const addEditBillingInfo = (data) => post(url.EDIT_BILLING_INFO, data);
export const setPassword = (data) => post(url.SET_PASSWORD, data);
export const deleteEnterprise = (enterpriseId) => del(`${url.DELETE_ENTERPRISE}/${enterpriseId}`);
export const getEnterpriseDashboardData = (data) => post(url.ENTERPRISE_DASHBOARD, data);
export const deleteSuperAdmin = () => post(url.DELETE_SUPERADMIN, {});

//payment
export const addCard = (data) => post(url.ADD_CARD, data);
export const getAllCards = (userId) => get(`${url.GET_ALL_CARDS}/${userId}`);
export const setDefaultCard = (data) => post(url.UPDATE_DEFAULT_CARD, data);
export const deleteCard = (data) => post(url.DELETE_CARD, data);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  createUserData,
  getadminData,
  getTradeData,
  getProfileData,
};
