import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const history = useHistory();
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("authUser");
    localStorage.removeItem("user");
    localStorage.clear();
    history.push("/super/login");
  };

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }
  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li>
            <Link to="/super/dashboard">
              <i className="bx bxs-dashboard"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/super/enterprise-list">
              <i className="bx bx-buildings"></i>
              <span>{props.t("Enterprises")}</span>
            </Link>
          </li>
          <li>
            <Link to="/email-template">
              <i className="bx bx-mail-send"></i>
              <span>{props.t("Email Template")}</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/super/static-pages">
              <i className="bx bxs-file"></i> <span>{props.t("Static Pages")}</span>
            </Link>
          </li> */}
          <li style={{ bottom: "0", position: "absolute" }}>
            <Button color="link" onClick={logout} className="logout-link">
              <i className="bx bxs-lock-alt fsr-25 align-middle "></i>
              <span className="ms-2">Log Out</span>
            </Button>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
