import axios from "axios"
const API_URL = process.env.REACT_APP_API

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function postLogin(url, data, config = {}) {
  let res = axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)

  return res
}
