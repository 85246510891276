import {
  GET_LIST,
  GET_LIST_SUCCESSFUL,
  GET_LIST_FAILED,
  GET_ARCHIVED_LIST,
  GET_ARCHIVED_LIST_SUCCESSFUL,
  GET_ARCHIVED_LIST_FAILED,
} from "./actionTypes"

export const getList = (  pageNo, pageSize, search, data  ) => {
  return {
    type: GET_LIST,
    payload: {  pageNo, pageSize, search, data  }
  }
}

export const getListSuccessful = listData => {
  return {
    type: GET_LIST_SUCCESSFUL,
    payload: listData,
  }
}

export const getListFailed = error => {
  return {
    type: GET_LIST_FAILED,
    payload: error,
  }
}
export const getArchiveList = (  pageNo, pageSize, search  ) => {
  return {
    type: GET_ARCHIVED_LIST,
    payload: {  pageNo, pageSize, search  }
  }
}

export const getArchiveListSuccessful = listData => {
  return {
    type: GET_ARCHIVED_LIST_SUCCESSFUL,
    payload: listData,
  }
}

export const getArchiveListFailed = error => {
  return {
    type: GET_ARCHIVED_LIST_FAILED,
    payload: error,
  }
}


