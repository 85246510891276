import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"

//Account Redux states
import {
  TOGGLE_STATUS,
  TECH_TOGGLE_STATUS,
  DELETE_PARTNER,
  ARCHIVED_STATUS,
} from "./actionTypes"
import {
  toggleSuccessful,
  toggleFailed,
  archivedSuccessful,
  archivedFailed,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper"
import {
  getToggle,
  getArchived,
  getDelete,
  getTechnicianToggle,
} from "../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* getToggleData({ payload: { data, history } }) {
  try {
    const response = yield call(getToggle, data)

    if (response) {
      yield put(toggleSuccessful(response.message))

      setTimeout(() => {
        if (window.location.pathname.includes("preferred-partner")) {
          history.push("/preferred-partner-list")
        } else {
          history.push("/admin-list")
        }
      }, 1000)
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      history.push("/login")
    }
    yield put(toggleFailed(error.response))
  }
}

function* getTechToggleData({ payload: { data, history } }) {
  try {
    const response = yield call(getTechnicianToggle, data)
    if (response) {
      yield put(toggleSuccessful(response.message))

      setTimeout(() => {
        history.push("/technicians-list")
      }, 50)
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      history.push("/login")
    }
    yield put(toggleFailed(error.response))
  }
}

function* getDeletePartner({ payload: { data } }) {
  try {
    const response = yield call(getDelete, data)
    if (response) {
      yield put(toggleSuccessful(response.message))
      setTimeout(() => {
        if (window.location.pathname.includes("preferred-partner")) {
          window.location = "/preferred-partner-list"
        } else {
          window.location = "/admin-list"
        }
      }, 1000)
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(toggleFailed(error.response))
  }
}

function* getArchivedData({ payload: { data } }) {
  console.log("data", data)
  try {
    const response = yield call(getArchived, data)
    console.log("response", response)
    if (response) {
      yield put(archivedSuccessful(response.message))
      setTimeout(() => {
        if (window.location.pathname.includes("preferred-partner")) {
          window.location = "/preferred-partner-list"
        } else {
          window.location = "/admin-list"
        }
      }, 1000)
    }
  } catch (error) {
    console.log("error", error)
    location.reload()
    yield put(archivedFailed(error.response))

    if (
      (error.response &&
        error.response.status &&
        error.response.status === 401) ||
      error.response.status === 400
    ) {
    }
    yield put(archivedFailed(error.response))
  }
}

function* ToggleSaga() {
  yield takeEvery(TOGGLE_STATUS, getToggleData)
  yield takeEvery(TECH_TOGGLE_STATUS, getTechToggleData)
  yield takeEvery(ARCHIVED_STATUS, getArchivedData)
  yield takeEvery(DELETE_PARTNER, getDeletePartner)
}

export default ToggleSaga
