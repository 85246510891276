import {
  ADD_NEW_TEMPLATE,
  ADD_NEW_TEMPLATE_SUCCESSFUL,
  ADD_NEW_TEMPLATE_FAILED,
  VIEW_SAVED_TEMPLATES,
  VIEW_SAVED_TEMPLATES_SUCCESS,
  VIEW_SAVED_TEMPLATES_FAILED,
  LOAD_TEMPLATE_FAILURE,
  LOAD_TEMPLATE,
  LOAD_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_FAILURE,
} from "./actionTypes"

const initialState = {
  message: null,
  error: null,
  loading: false,
  savedTemplates: [],
  templatesLoading: null,
  templatesLoadingError: null,
  templateLoadingError: null,
  templateLoading: null,
  template: null,
  editTemplateSuccess: null,
  editTemplateError: null,
}

const EmailTemplate = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_TEMPLATE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_NEW_TEMPLATE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
      }
      break
    case ADD_NEW_TEMPLATE_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      }
      break

    case VIEW_SAVED_TEMPLATES:
      state = {
        ...state,
        templatesLoading: true,
      }
      break
    case VIEW_SAVED_TEMPLATES_SUCCESS:
      state = {
        ...state,
        templatesLoading: false,
        savedTemplates: action.payload,
      }
      break
    case VIEW_SAVED_TEMPLATES_FAILED:
      state = {
        ...state,
        templatesLoading: false,
        templatesLoadingError: action.payload.error,
      }
      break

    case LOAD_TEMPLATE:
      state = {
        ...state,
        templateLoading: true,
      }
      break
    case LOAD_TEMPLATE_SUCCESS:
      state = {
        ...state,
        templateLoading: false,
        template: action.payload,
      }
      break
    case LOAD_TEMPLATE_FAILURE:
      state = {
        ...state,
        templateLoading: false,
        templateLoadingError: action.payload.error,
      }
      break
    case EDIT_TEMPLATE:
      state = {
        ...state,
        loading: true,
        editTemplateSuccess: null,
        editTemplateError: null,
      }
      break
    case EDIT_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        editTemplateSuccess: action.payload,
      }
      break
    case EDIT_TEMPLATE_FAILURE:
      state = {
        ...state,
        loading: false,
        editTemplateError: action.payload.error,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default EmailTemplate
