import {
  GET_ENTERPRISES,
  GET_ENTERPRISES_SUCCESSFUL,
  GET_ENTERPRISES_FAILED,
  CREATE_NEW_ENTERPRISE,
  CREATE_NEW_ENTERPRISE_SUCCESSFUL,
  CREATE_NEW_ENTERPRISE_FAILED,
  ACCESS_ACOUNT_BY_EMAIL,
  ACCESS_ACOUNT_BY_EMAIL_SUCCESSFUL,
  ACCESS_ACOUNT_BY_EMAIL_FAILED,
  ENTERPRISE_SIGNUP,
  ENTERPRISE_SIGNUP_SUCCESSFUL,
  ENTERPRISE_SIGNUP_FAILED,
  SET_THEME_COLOR,
  SET_THEME_COLOR_SUCCESSFUL,
  SET_THEME_COLOR_FAILED,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESSFUL,
  UPLOAD_LOGO_FAILED,
  TOGGLE_ENTERPRISE_STATUS,
  TOGGLE_ENTERPRISE_STATUS_SUCCESSFUL,
  TOGGLE_ENTERPRISE_STATUS_FAILED,
  TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS,
  TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS_SUCCESSFUL,
  TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS_FAILED,
  PROFILE_ENP_ERROR,
  PROFILE_ENP_SUCCESS,
  EDIT_ENPP_PROFILE,
  NEW_SUPER_ADMIN_SIGNUP,
  NEW_SUPER_ADMIN_SIGNUP_SUCESS,
  NEW_SUPER_ADMIN_SIGNUP_FAILED,
  EDIT_BILLING_INFO,
  EDIT_BILLING_INFO_SUCCESS,
  EDIT_BILLING_INFO_FAILURE,
  SET_ADMIN_PASSWORD_SUCCESS,
} from "./actionTypes";

const initialState = {
  enterprisesLoadingError: null,
  enterprises: [],
  loading: false,
  createEnterPriseSuccessMsg: null,
  createEnterPriseErrorMsg: null,
  id: null,
  accountSuccessMsg: null,
  accountErrorMsg: null,
  signupSuccess: null,
  signupErrorMsg: null,
  uploadLogoSuccess: null,
  uploadLogoError: null,
  themeColorSuccess: null,
  themeColorFailed: null,
  toggleEnterpriseStatusSuccess: null,
  toggleEnterpriseStatusError: null,
  toggleEnterprisePreferredPartnerStatusSuccess: null,
  toggleEnterprisePreferredPartnerStatusError: null,
  error: "",
  success: "",
  logoLoading: false,
  editBillingSuccess: null,
  editBillingError: null,
  profleUpdateSuccess: null,
  profileUpdateError: null,
  passwordCreated: false,
};

const enterprise = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ENPP_PROFILE:
      state = {
        ...state,
        loading: true,
        profileUpdateError: null,
        profleUpdateSuccess: null,
      };
      break;
    case SET_ADMIN_PASSWORD_SUCCESS:
      state = {
        ...state,
        passwordCreated: action.payload,
      };
    case PROFILE_ENP_SUCCESS:
      state = {
        ...state,
        loading: false,
        profleUpdateSuccess: action.payload,
      };
      break;
    case PROFILE_ENP_ERROR:
      state = {
        ...state,
        loading: false,
        profileUpdateError: action.payload,
      };
      break;
    case GET_ENTERPRISES:
      state = {
        ...state,
        loading: true,
        enterprisesLoadingError: null,
      };
      break;
    case GET_ENTERPRISES_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        enterprises: action.payload,
      };
      break;
    case GET_ENTERPRISES_FAILED:
      state = {
        ...state,
        loading: false,
        enterprisesLoadingError: action.payload,
      };
      break;
    case CREATE_NEW_ENTERPRISE:
      state = {
        ...state,
        loading: true,
        createEnterPriseSuccessMsg: null,
        createEnterPriseErrorMsg: null,
      };
      break;
    case CREATE_NEW_ENTERPRISE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        createEnterPriseSuccessMsg: action.payload,
      };
      break;
    case CREATE_NEW_ENTERPRISE_FAILED:
      state = {
        ...state,
        loading: false,
        createEnterPriseErrorMsg: action.payload,
      };
      break;
    case ACCESS_ACOUNT_BY_EMAIL:
      state = {
        ...state,
        loading: true,
        accountSuccessMsg: null,
        accountErrorMsg: null,
      };
      break;
    case ACCESS_ACOUNT_BY_EMAIL_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        accountSuccessMsg: action.payload,
      };
      break;
    case ACCESS_ACOUNT_BY_EMAIL_FAILED:
      state = {
        ...state,
        loading: false,
        accountErrorMsg: action.payload,
      };
      break;
    case ENTERPRISE_SIGNUP:
      state = {
        ...state,
        loading: true,
        signupErrorMsg: null,
      };
      break;
    case ENTERPRISE_SIGNUP_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        signupSuccess: action.payload,
      };
      break;
    case ENTERPRISE_SIGNUP_FAILED:
      state = {
        ...state,
        loading: false,
        signupErrorMsg: action.payload,
      };
      break;
    case SET_THEME_COLOR:
      state = {
        ...state,
        loading: true,
        themeColor: null,
      };
      break;
    case SET_THEME_COLOR_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        themeColorSuccess: action.payload,
      };
      break;
    case SET_THEME_COLOR_FAILED:
      state = {
        ...state,
        loading: false,
        themeColorFailed: action.payload,
      };
      break;
    case UPLOAD_LOGO:
      state = {
        ...state,
        logoLoading: true,
        uploadLogoSuccess: null,
        uploadLogoError: null,
      };
      break;
    case UPLOAD_LOGO_SUCCESSFUL:
      state = {
        ...state,
        logoLoading: false,
        uploadLogoSuccess: action.payload,
        uploadLogoError: null,
      };
      break;
    case UPLOAD_LOGO_FAILED:
      state = {
        ...state,
        logoLoading: false,
        uploadLogoError: null,
        uploadLogoError: action.payload,
      };
    case TOGGLE_ENTERPRISE_STATUS:
      state = {
        ...state,
        loading: true,
        toggleEnterpriseStatusSuccess: null,
        toggleEnterpriseStatusError: null,
      };
      break;
    case TOGGLE_ENTERPRISE_STATUS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,

        toggleEnterpriseStatusSuccess: action.payload,
      };
      break;
    case TOGGLE_ENTERPRISE_STATUS_FAILED:
      state = {
        ...state,
        loading: false,
        toggleEnterpriseStatusError: action.payload,
      };
      break;
    case TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS:
      state = {
        ...state,
        loading: true,
        toggleEnterprisePreferredPartnerStatusSuccess: null,
        toggleEnterprisePreferredPartnerStatusError: null,
      };
      break;
    case TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        toggleEnterprisePreferredPartnerStatusSuccess: action.payload,
      };
      break;
    case TOGGLE_ENTERPRISE_PREFERRED_PARTNER_STATUS_FAILED:
      state = {
        ...state,
        loading: false,
        toggleEnterprisePreferredPartnerStatusError: action.payload,
      };
      break;
    case NEW_SUPER_ADMIN_SIGNUP:
      state = {
        ...state,
        loading: true,
        signupSuccess: null,
        signupFailed: null,
      };
      break;
    case NEW_SUPER_ADMIN_SIGNUP_SUCESS:
      state = {
        ...state,
        loading: false,
        signupSuccess: action.payload,
      };
      break;
    case NEW_SUPER_ADMIN_SIGNUP_FAILED:
      state = {
        ...state,
        loading: false,
        signupFailed: action.payload,
      };
      break;
    case EDIT_BILLING_INFO:
      state = {
        ...state,
        loading: true,
        editBillingError: null,
        editBillingSuccess: null,
      };
      break;
    case EDIT_BILLING_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        editBillingSuccess: action.payload,
        editBillingError: null,
      };
      break;
    case EDIT_BILLING_INFO_FAILURE:
      state = {
        ...state,
        loading: false,
        editBillingError: action.payload,
        editBillingSuccess: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default enterprise;
