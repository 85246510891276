/**
 * @Author: ZEESHAN AHMAD
 * @Date:   2021-11-01 01:11:41
 * @Last Modified by:   ZEESHAN AHMAD
 * @Last Modified time: 2021-11-04 10:31:52
 */
import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { GET_TECHNICIAN_LIST } from "./actionTypes"
import {
  getTechniciansListSuccessful,
  getTechniciansListFailed,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { getTechniciansData } from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* fetchProducts({ payload: { pageNo, pageSize, search, data } }) {
  try {
    const response = yield call(
      getTechniciansData,
      pageNo,
      pageSize,
      search,
      data
    )

    yield put(getTechniciansListSuccessful(response))
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(getTechniciansListFailed(error.response))
  }
}

function* TechnicianListSaga() {
  yield takeEvery(GET_TECHNICIAN_LIST, fetchProducts)
}

export default TechnicianListSaga
