import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { GET_DATA } from "./actionTypes";
import { getDataSuccessful, getDataFailed, getEnterpriseSuccessful } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { getProfileData } from "../../helpers/fakebackend_helper";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* fetchProfileData({ payload: { data, history, noCache, userType } }) {
  try {
    const response = yield call(getProfileData, data);
    if (response) {
      if (!noCache) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      if (userType) {
        yield put(getEnterpriseSuccessful(response.data));
      } else {
        yield put(getDataSuccessful(response.data));
      }

      if (history && history.location.pathname !== "/admin-list") {
      } else {
        history.push("/admin-list");
      }
    }
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) {
      window.location = "/login";
    }
    yield put(getDataFailed(error.response));
  }
}

function* ProfileDataSaga() {
  yield takeEvery(GET_DATA, fetchProfileData);
}

export default ProfileDataSaga;
