/**
 * @Author: ZEESHAN AHMAD
 * @Date:   2021-11-01 01:11:41
 * @Last Modified by:   ZEESHAN AHMAD
 * @Last Modified time: 2021-11-04 11:03:14
 */
import axios from "axios"
const API_URL = process.env.REACT_APP_API

const axiosApi = axios.create({
  baseURL: API_URL,
})

export function initToken() {
  axiosApi.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`
}

axiosApi.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("token")}`

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  let res = axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
  return res
}

export async function downloadpost(url, data, config = {}) {
  let res = axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.blob())
  return res
}

export async function formpost(url, data, config = {}) {
  return axiosApi
    .post(`${url}`, data, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, data, config = {}) {
  return await axiosApi
    .delete(url, { ...data }, { ...config })
    .then(response => response.data)
}
