import {
  TOGGLE_STATUS,
  TECH_TOGGLE_STATUS,
  TOGGLE_SUCCESSFUL,
  TOGGLE_FAILED,
  ARCHIVED_STATUS,
  ARCHIVED_SUCCESSFUL,
  ARCHIVED_FAILED,
  DELETE_PARTNER
} from "./actionTypes"

const initialState = {
  message:null,
  error: null,
  toggledata: [],
  loading: false,
}

const Toggle = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_STATUS:
      state = {
        ...state,
        loading: true,
        user: action.payload,
      }
      break
    case TECH_TOGGLE_STATUS:
      state = {
        ...state,
        loading: true,
        user: action.payload,
      }
      break
    case DELETE_PARTNER:
      state = {
        ...state,
        loading: true,
        user: action.payload,
      }
      break
    case TOGGLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
      }
      break
    case TOGGLE_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    case ARCHIVED_STATUS:
      state = {
        ...state,
        loading: true,
        user: action.payload,
      }
      break
    case ARCHIVED_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
      }
      break
    case ARCHIVED_FAILED:
      state = {
        ...state,
        loading: false,
        toggleError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Toggle
