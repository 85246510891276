import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER, INVITE_NEW_SUPER_ADMIN, GET_NEW_SUPER_LINK } from "./actionTypes";
import {
  registerUserSuccessful,
  registerUserFailed,
  inviteNewSuperAdminSuccess,
  inviteNewSuperAdminFailed,
  getSuperLinkInfoSuccess,
  getSuperLinkInfoFailed,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeRegister,
  postJwtRegister,
  sendNewSuperLink,
  getNewSuperLinkInfo,
} from "../../../helpers/fakebackend_helper";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.registerUser, user.email, user.password);
      yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user);
      yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user);
      yield put(registerUserSuccessful(response));
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* NewInviteSend({ payload: { email, history } }) {
  console.log(email);
  try {
    const response = yield call(sendNewSuperLink, email);
    yield put(inviteNewSuperAdminSuccess(response.message));
    setTimeout(() => {
      // history.push("/super/signup/:invitecode")
    }, 1000);
  } catch (error) {
    yield put(inviteNewSuperAdminFailed("Invitation Already Sent"));
  }
}
function* getSuperLinkInfo({ payload: { inviteUrl } }) {
  try {
    const response = yield call(getNewSuperLinkInfo, inviteUrl);
    yield put(getSuperLinkInfoSuccess(response.user));
    console.log(response);
    yield put(setLinkData(response.user));
    localStorage.setItem("super_user", JSON.stringify(response.user));
  } catch (error) {
    yield put(getSuperLinkInfoFailed(error));
  }
}

//signup

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(INVITE_NEW_SUPER_ADMIN, NewInviteSend);
  yield takeEvery(GET_NEW_SUPER_LINK, getSuperLinkInfo);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
