import {
  DELETE_TRADE,
  DELETE_TRADE_SUCCESSFUL,
  DELETE_TRADE_FAILED,
} from "./actionTypes"

export const deleteTrade = (user, history) => {
  return {
    type: DELETE_TRADE,
    payload: { user },
  }
}

export const deleteTradeSuccessful = user => {
  return {
    type: DELETE_TRADE_SUCCESSFUL,
    payload: user,
  }
}

export const deleteTradeFailed = error => {
  return {
    type: DELETE_TRADE_FAILED,
    payload: error,
  }
}
