import PropTypes from "prop-types";
import React, { useEffect, useState, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Offline, Online } from "react-detect-offline";
import NoInternet from "./NoInternet";
import "./App.css";
// Import Routes all
import {
  userRoutes,
  authRoutes,
  superAdminRoutes,
  nonAuthSuper,
} from "./routes/allRoutes";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
import SuperAdminMiddleware from "./routes/middleware/SuperAdminAuthMiddleware";
// layouts Format
import EnterPriseLAyout from "./components/EnterPriseLayout";
import NonAuthLayout from "./components/NonAuthLayout";
import SuperAdminLayout from "./components/SuperAdminLayout";
//not found
import NotFound from "./NotFound";
// Import scss
import "./assets/scss/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "./Preloader";
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { getProfileData } from "./store/actions";

// Activating fake backend
fakeBackend();

const App = (props) => {
  const [loader, setLoader] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    // changing colour of the theme if enterprise is logged in
    if (user && user.themeColor) {
      console.log("color present");
      document.documentElement.style.setProperty(
        "--theme-color1",
        user.themeColor
      );
      document.documentElement.style.setProperty(
        "--chargediv-color",
        user.themeColor
      );
      document.documentElement.style.setProperty(
        "--pagnation-color",
        user.themeColor
      );
    } else {
      // setting default color
      document.documentElement.style.setProperty(
        "--pagnation-color",
        "#f58120"
      );
      document.documentElement.style.setProperty("--theme-color1", "#f58120");
      document.documentElement.style.setProperty(
        "--chargediv-color",
        "#ffe9d7"
      );
    }
  });

  useEffect(() => {
    if (user && user["userType"] === "Enterprise") {
      const obj = {
        userId: user["_id"],
      };
      props.onGetProfile(obj, null, false, true);
    }
  }, []);

  function getLayout() {
    let layoutCls = EnterPriseLAyout;
    switch (props.layout.layoutType) {
      case "horizontal":
        break;
      default:
        layoutCls = EnterPriseLAyout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Online>
        <Router>
          <ToastContainer autoClose={2000} limit={1} />
          <Switch>
 
            <Suspense fallback={<Preloader />}>
              
              {authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  name={route.name}
                  exact
                />
              ))}

              {userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  name={route.name}
                  exact
                />
              ))}

              {/*{serviceAdminRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={ServiceAdminLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}

              {technicianRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={TechnicianLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}*/}
              {superAdminRoutes.map((route, idx) => (
                <SuperAdminMiddleware
                  path={route.path}
                  layout={SuperAdminLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  name={route.name}
                  exact
                />
              ))}
              {nonAuthSuper.map((route, idx) => (
                <SuperAdminMiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  name={route.name}
                  exact
                />
              ))}
            </Suspense>
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Online>
      <Offline>
        <NoInternet />
      </Offline>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetProfile: (urldata, history, noCache, userType) =>
    dispatch(getProfileData(urldata, history, noCache, userType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
