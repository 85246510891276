import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { editProfileApi } from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user, file, history } }) {
  const formData = new FormData()
  Object.keys(user).forEach(key => {
    formData.append(key, user[key])
    console.log(key, user[key], formData)
  })
  formData.append("profilePic", file)
  console.log(formData)
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(editProfileApi, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      yield put(profileSuccess(response.message))
      history.push("/super/super-admin-setting")
    }
  } catch (error) {
    yield put(profileError(error))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
