import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { GET_TRADE_LIST, GET_ENTERPRISE_TRADE_LIST } from "./actionTypes"
import {
  getTradeListSuccessful,
  getTradeListFailed,
  getEnterpriseTradeListSuccessful,
  getEnterpriseTradeListFailed,
} from "./actions"
import {
  getTradeData,
  getEntpTradeData,
} from "../../../helpers/fakebackend_helper"
function* fetchTradeList({ payload: { data } }) {
  try {
    const response = yield call(getTradeData, data)
    yield put(getTradeListSuccessful(response.data))
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(getTradeListFailed(error.response))
  }
}

function* fetchEnterpriseTradeList({ payload: { search } }) {
  console.log("search", search)
  try {
    const response = yield call(getEntpTradeData, search)
    yield put(getEnterpriseTradeListSuccessful(response.data))
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      // window.location = "/login"
    }
    yield put(getEnterpriseTradeListFailed(error.response))
  }
}

function* TradesListSaga() {
  yield takeEvery(GET_TRADE_LIST, fetchTradeList)
  yield takeEvery(GET_ENTERPRISE_TRADE_LIST, fetchEnterpriseTradeList)
}

export default TradesListSaga
