import {
  TECHNIAN_DASHBOARD_COUNT_FAILURE,
  TECHNIAN_DASHBOARD_COUNT_SUCCESS,
  TECHNIAN_DASHBORD_COUNT,
} from "./actionTypes"

const initialState = {
  listError: null,
  adminListData: [],
  loading: false,
  techdashboardCount: [],
}

const TechnianDashboard = (state = initialState, action) => {
  switch (action.type) {
    case TECHNIAN_DASHBORD_COUNT:
      state = {
        ...state,
        loading: true,
        creationError: null,
      }
      break
    case TECHNIAN_DASHBOARD_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        techdashboardCount: action.payload,
      }
      break
    case TECHNIAN_DASHBOARD_COUNT_FAILURE:
      state = {
        ...state,
        loading: false,
        listError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default TechnianDashboard
