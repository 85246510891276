import {
  ADD_TRADE,
  ADD_TRADE_SUCCESSFUL,
  ADD_TRADE_FAILED,
} from "./actionTypes"

export const addTrade = (user, history, cb) => {
  return {
    type: ADD_TRADE,
    payload: { user, history, cb },
  }
}

export const addTradeSuccessful = user => {
  return {
    type: ADD_TRADE_SUCCESSFUL,
    payload: user,
  }
}

export const addTradeFailed = error => {
  return {
    type: ADD_TRADE_FAILED,
    payload: error,
  }
}
