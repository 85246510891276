import { takeEvery, takeLatest, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { GET_ADMIN_LIST, GET_DASHBORD_COUNT } from "./actionTypes"
import {
  getAdminListSuccessful,
  getAdminListFailed,
  dashboardCountFailure,
  DashboardCountSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  getadminData,
  getDashboardCount,
} from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* fetchProducts({ payload: { pageNo, pageSize, search, data } }) {
  let obj = { perPage: pageSize, page: pageNo, search: search, status: data }
  try {
    const response = yield call(getadminData, obj)
    yield put(getAdminListSuccessful(response))
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(getAdminListFailed(error.response))
  }
}

function* getCount() {
  try {
    const response = yield call(getDashboardCount)
    yield put(DashboardCountSuccess(response))
  } catch (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      window.location = "/login"
    }
    yield put(dashboardCountFailure(error.response))
  }
}

function* AdminListSaga() {
  yield takeEvery(GET_ADMIN_LIST, fetchProducts)
  yield takeLatest(GET_DASHBORD_COUNT, getCount)
}

export default AdminListSaga
